export default class {
  static get() {
    var logins = [{
      email: 'admin@plunk.io',
      password: 'mis001zax',
      tenant: 'anbsezhvanaj',
      account: {
        tenantId: 'anbsezhvanaj',
        account: 'RobbStucky',
        company: 'Robb & Stucky',
        domain: 'https://robbstucky.com',
        whiteLabel: true,
        showCampaigns: false,
        product: {
          url: '/Product/Detail/:sku/:vendor/:name',
          hasName: true,
          hasSku: true
        }
      }
    }, {
      email: 'plunk@robbstuckyintl.com',
      password: 'rs3457Z!',
      tenant: 'anbsezhvanaj',
      account: {
        tenantId: 'anbsezhvanaj',
        account: 'RobbStucky',
        company: 'Robb & Stucky',
        domain: 'https://robbstucky.com',
        whiteLabel: true,
        showCampaigns: false,
        product: {
          url: '/Product/Detail/:sku/:vendor/:name',
          hasName: true,
          hasSku: true
        }
      }
    }, {
      email: 'echien@robbstuckyintl.com',
      password: 'ec9457X@!',
      tenant: 'anbsezhvanaj',
      account: {
        tenantId: 'anbsezhvanaj',
        account: 'RobbStucky',
        company: 'Robb & Stucky',
        domain: 'https://robbstucky.com',
        whiteLabel: true,
        showCampaigns: false,
        product: {
          url: '/Product/Detail/:sku/:vendor/:name',
          hasName: true,
          hasSku: true
        }
      }
    }, {
      email: 'kmartone@robbstuckyintl.com',
      password: 'RS@3tUckY7!',
      tenant: 'anbsezhvanaj',
      account: {
        tenantId: 'anbsezhvanaj',
        account: 'RobbStucky',
        company: 'Robb & Stucky',
        domain: 'https://robbstucky.com',
        whiteLabel: true,
        showCampaigns: false,
        product: {
          url: '/Product/Detail/:sku/:vendor/:name',
          hasName: true,
          hasSku: true
        }
      }
    }, {
      email: 'mevans@robbstuckyintl.com',
      password: 'me6217YW!',
      tenant: 'anbsezhvanaj',
      account: {
        tenantId: 'anbsezhvanaj',
        account: 'RobbStucky',
        company: 'Robb & Stucky',
        domain: 'https://robbstucky.com',
        whiteLabel: true,
        showCampaigns: false,
        product: {
          url: '/Product/Detail/:sku/:vendor/:name',
          hasName: true,
          hasSku: true
        }
      }
    }, {
      email: 'mstuart@robbstuckyintl.com',
      password: 'ms8627Z@!',
      tenant: 'anbsezhvanaj',
      account: {
        tenantId: 'anbsezhvanaj',
        account: 'RobbStucky',
        company: 'Robb & Stucky',
        domain: 'https://robbstucky.com',
        whiteLabel: true,
        showCampaigns: false,
        product: {
          url: '/Product/Detail/:sku/:vendor/:name',
          hasName: true,
          hasSku: true
        }
      }
    }, {
      email: 'mhess@robbstuckyintl.com',
      password: 'mh9647V@!',
      tenant: 'anbsezhvanaj',
      account: {
        tenantId: 'anbsezhvanaj',
        account: 'RobbStucky',
        company: 'Robb & Stucky',
        domain: 'https://robbstucky.com',
        whiteLabel: true,
        showCampaigns: false,
        product: {
          url: '/Product/Detail/:sku/:vendor/:name',
          hasName: true,
          hasSku: true
        }
      }
    }, {
      email: 'mmcmahon@robbstuckyintl.com',
      password: 'mm2349X!',
      tenant: 'anbsezhvanaj',
      account: {
        tenantId: 'anbsezhvanaj',
        account: 'RobbStucky',
        company: 'Robb & Stucky',
        domain: 'https://robbstucky.com',
        whiteLabel: true,
        showCampaigns: false,
        product: {
          url: '/Product/Detail/:sku/:vendor/:name',
          hasName: true,
          hasSku: true
        }
      }
    }, {
      email: 'dewest@robbstuckyintl.com',
      password: 'dw7142N!',
      tenant: 'anbsezhvanaj',
      account: {
        tenantId: 'anbsezhvanaj',
        account: 'RobbStucky',
        company: 'Robb & Stucky',
        domain: 'https://robbstucky.com',
        whiteLabel: true,
        showCampaigns: false,
        product: {
          url: '/Product/Detail/:sku/:vendor/:name',
          hasName: true,
          hasSku: true
        }
      }
    }, {
      email: 'szigler@robbstuckyintl.com',
      password: 'sz5642M!',
      tenant: 'anbsezhvanaj',
      account: {
        tenantId: 'anbsezhvanaj',
        account: 'RobbStucky',
        company: 'Robb & Stucky',
        domain: 'https://robbstucky.com',
        whiteLabel: true,
        showCampaigns: false,
        product: {
          url: '/Product/Detail/:sku/:vendor/:name',
          hasName: true,
          hasSku: true
        }
      }
    }, {
      email: 'rferris@robbstuckyintl.com',
      password: 'rf2851Q!',
      tenant: 'anbsezhvanaj',
      account: {
        tenantId: 'anbsezhvanaj',
        account: 'RobbStucky',
        company: 'Robb & Stucky',
        domain: 'https://robbstucky.com',
        whiteLabel: true,
        showCampaigns: false,
        product: {
          url: '/Product/Detail/:sku/:vendor/:name',
          hasName: true,
          hasSku: true
        }
      }
    }, {
      email: 'plunk@ambellahome.com',
      password: 'ah72Q8x!',
      tenant: 'a554387357817',
      account: {
        tenantId: 'a554387357817',
        account: 'Ambella',
        company: 'Ambella Home',
        domain: 'https://ambellahome.com',
        whiteLabel: true,
        showCampaigns: false,
        product: {
          url: '/Product/Detail/:name/:sku',
          hasName: true,
          hasSku: true
        }
      }
    }, {
      email: 'cholt@caracole.com',
      password: 'ch63VwZ!',
      tenant: 'a371826588698',
      account: {
        tenantId: 'a371826588698',
        account: 'Caracole',
        company: 'Caracole',
        domain: 'https://caracole.com',
        whiteLabel: true,
        showCampaigns: true,
        product: {
          url: '/Product/Detail/:sku',
          hasName: false,
          hasSku: true
        }
      }
    }, {
      email: 'hgordon@caracole.com',
      password: 'ch72MpX!',
      tenant: 'a371826588698',
      account: {
        tenantId: 'a371826588698',
        account: 'Caracole',
        company: 'Caracole',
        domain: 'https://caracole.com',
        whiteLabel: true,
        showCampaigns: true,
        product: {
          url: '/Product/Detail/:sku',
          hasName: false,
          hasSku: true
        }
      }
    }, {
      email: 'demo@plunk.io',
      password: 'db72MpX!',
      tenant: 'anbsezhvanaj',
      account: {
        tenantId: 'anbsezhvanaj',
        account: 'RobbStucky',
        company: 'Demo',
        domain: 'https://robbstucky.com',
        whiteLabel: true,
        showCampaigns: false,
        product: {
          url: '/Product/Detail/:sku/:vendor/:name',
          hasName: true,
          hasSku: true
        }
      }
    }, {
      email: 'sspiro@seagatedevelopmentgroup.com',
      password: 'sgWxbr7!',
      tenant: 'a795726985642',
      account: {
        tenantId: 'a795726985642',
        account: 'SeaGate',
        company: 'SeaGate',
        domain: 'https://seagatedevelopmentgroup.com',
        whiteLabel: true,
        showCampaigns: true,
        product: {
          url: null,
          hasName: false,
          hasSku: true
        }
      }
    }, {
      email: 'chensley@seagatedevelopmentgroup.com',
      password: 'sgZQGy3!',
      tenant: 'a795726985642',
      account: {
        tenantId: 'a795726985642',
        account: 'SeaGate',
        company: 'SeaGate',
        domain: 'https://seagatedevelopmentgroup.com',
        whiteLabel: true,
        showCampaigns: true,
        product: {
          url: null,
          hasName: false,
          hasSku: true
        }
      }
    }, {
      email: 'sandra@theorydesign.com',
      password: 'sgWxbr7!',
      tenant: 'a934961635182',
      account: {
        tenantId: 'a934961635182',
        account: 'TheoryDesign',
        company: 'Theory Design',
        domain: 'theorydesign.com',
        whiteLabel: true,
        showCampaigns: true,
        product: {
          url: null,
          hasName: false,
          hasSku: true
        }
      }
    }, {
      email: 'chris@theorydesign.com',
      password: 'sgZQGy3!',
      tenant: 'a934961635182',
      account: {
        tenantId: 'a934961635182',
        account: 'TheoryDesign',
        company: 'Theory Design',
        domain: 'theorydesign.com',
        whiteLabel: true,
        showCampaigns: true,
        product: {
          url: null,
          hasName: false,
          hasSku: true
        }
      }
    }];
    return logins;
  }

}