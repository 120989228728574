export const rangeLabel = value => {
  switch (value) {
    case 'yesterday':
      return 'Yesterday';

    case 'this_week':
      return 'This Week';

    case 'last_week':
      return 'Last Week';

    case 'this_month':
      return 'This Month';

    case 'last_month':
      return 'Last Month';

    case 'this_quarter':
      return 'This Quarter';

    case 'last_quarter':
      return 'Last Quarter';

    case 'this_year':
      return 'This Year';

    case 'last_year':
      return 'Last Year';

    case 'all_time':
      return 'All Time';

    default:
      return 'Today';
  }
};
export default rangeLabel;