export default {
  root: 'https://api.plunk.io/v1/activity',
  protocol: 'https',
  host: 'api.plunk.io',
  path: '/v1/activity',
  port: 443,
  activity: {
    root: 'https://api.plunk.io/v1/activity',
    protocol: 'https',
    host: 'api.plunk.io',
    path: '/v1/activity',
    port: 443
  },
  audience: {
    root: 'https://api.plunk.io/v1',
    protocol: 'https',
    host: 'api.plunk.io',
    path: '/v1',
    port: 443
  },
  product: {
    root: 'https://api.plunk.io/v1',
    protocol: 'https',
    host: 'api.plunk.io',
    path: '/v1',
    port: 443
  },
  location: {
    root: 'https://api.plunk.io/v1',
    protocol: 'https',
    host: 'api.plunk.io',
    path: '/v1',
    port: 443
  },
  forecast: {
    root: 'https://api.plunk.io/v1',
    protocol: 'https',
    host: 'api.plunk.io',
    path: '/v1',
    port: 443
  },
  assistant: {
    root: 'https://api.plunk.io/v1',
    protocol: 'https',
    host: 'api.plunk.io',
    path: '/v1',
    port: 443
  },
  campaign: {
    root: 'https://campaign.plunk.io/api',
    protocol: 'https',
    host: 'campaign.plunk.io',
    path: '/api',
    port: 443
  }
};