export class LocalCache {
  static get(key) {
    var value = localStorage.getItem(key);

    try {
      value = JSON.parse(value);
    } catch (ex) {}

    return value;
  }

  static set(key, value) {
    if (typeof value === 'object') value = JSON.stringify(value);
    localStorage.setItem(key, value);
  }

  static key(index) {
    return localStorage.key(index);
  }

  static delete(key) {
    localStorage.removeItem(key);
  }

  static get count() {
    return localStorage.length;
  }

  static clear() {
    localStorage.clear();
  }

}
export class SessionCache {
  static get(key) {
    var value = sessionStorage.getItem(key);

    try {
      value = JSON.parse(value);
    } catch (ex) {}

    return value;
  }

  static set(key, value) {
    if (typeof value === 'object') value = JSON.stringify(value);
    sessionStorage.setItem(key, value);
  }

  static key(index) {
    return sessionStorage.key(index);
  }

  static delete(key) {
    sessionStorage.removeItem(key);
  }

  static get count() {
    return sessionStorage.length;
  }

  static clear() {
    sessionStorage.clear();
  }

}
export class CookieCache {
  static get(key) {
    var value = this._get(key);

    if (!value) return null;

    try {
      value = JSON.parse(value);
    } catch (ex) {}

    return value;
  }

  static set(key, value) {
    if (typeof value === 'object') value = JSON.stringify(value);

    this._set(key, value);
  }

  static delete(key) {
    document.cookie = key + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

  static _get(key) {
    var name = key + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');

    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];

      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }

      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }

    return null;
  }

  static _set(key, value) {
    var d = new Date();
    d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = key + "=" + value + ";" + expires + ";path=/";
  }

}
export default {
  LocalCache,
  SessionCache,
  CookieCache
};