export const replace = data => {
  if (!data) {
    return '';
  }

  var lower = data.toLowerCase();

  if (lower === 'engagement') {
    return 'Interaction';
  } else {
    return data;
  }
};
export default replace;