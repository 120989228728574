import account from "../data/account";

var UrlPattern = require('url-pattern');

import { LocalCache } from "../../jsx/cache/cache";
import moment from "moment";
export default class {
  static process(data, range) {
    console.log(data);
    var viewsArray = this.views(data);
    var topTen = this.topTen(viewsArray);
    var chartData = this.chartData(topTen);
    this.save(chartData, range);
    return chartData;
  }

  static processStats(stats) {
    var data = [];
    data.push(['name', 'views', {
      type: 'string',
      role: 'tooltip'
    }]);
    stats.forEach(item => {
      var name = item.title;
      var title = '';

      if (name) {
        name = name.replace(/-/g, ' ');
        name = name.replace(/%20/g, ' ');
        title = item.productId + '\n' + name + '\n' + 'Views:' + item.views;
      } else {
        title = item.productId + '\nViews:' + item.views;
      }

      data.push([name, item.views, title]);
    });
    return data;
  }

  static mock(data) {
    return [['sku', 'count'], ['111-113-090', 50000], ['112-123-061', 45000], ['113-107-070', 35000], ['114-108-050', 25000], ['115-109-040', 20000], ['116-110-220', 15000], ['117-111-160', 10000], ['118-112-081', 9000], ['119-110-191', 8000], ['120-199-177', 5000]];
  }

  static chartData(arr) {
    var data = [];
    data.push(['sku', 'count', {
      type: 'string',
      role: 'tooltip'
    }]);
    arr.forEach(item => {
      var name = item.name;
      var title = '';

      if (name) {
        name = name.replace(/-/g, ' ');
        name = name.replace(/%20/g, ' ');
        title = item.sku + '\n' + name + '\n' + 'Count:' + item.count;
      } else {
        title = item.sku + '\nCount:' + item.count;
      }

      data.push([item.sku, item.count, title]);
    });
    console.log(data);
    return data;
  }

  static topTen(views) {
    return views.slice(0, 10);
  }

  static views(impressions) {
    var accountModel = account.get();
    var domain = accountModel.domain;
    var product = accountModel.product;
    var url = product.url;
    var viewsArray = [];
    impressions.forEach(item => {
      var pattern = new UrlPattern(url);
      var itemUrl = item.url.replace(domain, '');
      var split = itemUrl.split('?');
      itemUrl = split[0];
      var result = pattern.match(itemUrl);

      if (result) {
        viewsArray = this.updateViewsArray(viewsArray, result);
      }
    });

    if (viewsArray.length) {
      viewsArray.sort((a, b) => {
        return b.count - a.count;
      });
    }

    return viewsArray;
  }

  static updateViewsArray(arr, item) {
    var obj = {};
    var updateIndex = -1;

    if (arr.length) {
      var index = -1;
      arr.forEach(ele => {
        index += 1;

        if (ele.sku === item.sku) {
          ele.count += 1;
          obj = ele;
          updateIndex = index;
        }
      });

      if (updateIndex > -1) {
        arr[updateIndex] = obj;
      } else {
        obj.sku = item.sku;
        obj.name = item.name;
        obj.count = 1;
        arr.push(obj);
      }
    } else {
      obj.sku = item.sku;
      obj.name = item.name;
      obj.count = 1;
      arr.push(obj);
    }

    return arr;
  }

  static save(chartData, range) {
    var key = 'product_urls_' + range;
    var value = {
      date: moment(),
      data: chartData
    };
    LocalCache.set(key, value);
  }

  static get(range) {
    var key = 'product_urls_' + range;
    var value = LocalCache.get(key);

    if (value) {
      var cachedDate = value.date;
      var currentDate = moment();
      var difference = currentDate.diff(cachedDate, 'hours');

      if (difference < 25) {
        return value.data;
      } else {
        LocalCache.delete(key);
        return null;
      }
    } else {
      return null;
    }
  }

}