export const pieChartOptions = () => {
  return {
    is3D: false,
    chartArea: {
      left: 10,
      top: 20,
      width: "100%",
      height: "100%"
    }
  };
};
export default pieChartOptions;