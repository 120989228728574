export default class {
  static generate(rows, columns) {
    var rowArrays = [];
    var strColumns = '';
    var columnLength = columns.length;
    var columnIndex = 0;
    columns.forEach(col => {
      columnIndex += 1;
      strColumns += col.title;

      if (columnIndex < columnLength) {
        strColumns += ',';
      } else {
        strColumns += '\n';
      }
    });
    rows.forEach(row => {
      var rowArray = [];

      for (const [key, value] of Object.entries(row)) {
        let sValue = value;

        if (typeof value === 'string') {
          sValue = sValue.replace(/,/g, ' ');
        }

        rowArray.push(sValue);
      }

      rowArrays.push(rowArray);
    });
    var csv = strColumns;
    rowArrays.forEach(row => {
      csv += row.join(',');
      csv += '\n';
    });
    return csv;
  }

}