export default class {
  static get models() {
    return [{
      label: 'gemini-pro',
      value: 'gemini-pro',
      service: 'Gemini'
    }, {
      label: 'gpt-4-0125-preview',
      value: 'gpt-4-0125-preview',
      service: 'OpenAI'
    }, {
      label: 'gpt-4-turbo-preview',
      value: 'gpt-4-turbo-preview',
      service: 'OpenAI'
    }, {
      label: 'gpt-4',
      value: 'gpt-4',
      service: 'OpenAI'
    }];
  }

  static getEmbeddings(service) {
    service = service.toLowerCase();
    console.log(service);

    if (service === 'gemini') {
      return [{
        label: 'embedding-001',
        value: 'embedding-001'
      }];
    } else {
      return [{
        label: 'text-embedding-ada-002',
        value: 'text-embedding-ada-002'
      }, {
        label: 'text-embedding-3-small',
        value: 'text-embedding-3-small'
      }, {
        label: 'text-embedding-3-large',
        value: 'text-embedding-3-large'
      }];
    }
  }

}