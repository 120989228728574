import PubSub from 'pubsub-js';
export const publishSearch = (value, isMulti = false, multiType = null, multiSelection = null, multiSelectionText = null) => {
  setTimeout(() => {
    PubSub.publish('search', {
      value: value,
      isMulti: isMulti,
      multiType: multiType,
      multiSelection: multiSelection,
      multiSelectionText: multiSelectionText
    });
  }, 10);
};
export default publishSearch;