export const calcPerformanceScore = str => {
  var score = "";
  str = str.toLowerCase();

  if (str.indexOf('excellent') > -1) {
    score = 'excellent';
  } else if (str.indexOf('above average') > -1) {
    score = 'above average';
  } else if (str.indexOf('below average') > -1) {
    score = 'below average';
  } else if (str.indexOf('average') > -1) {
    score = 'average';
  } else if (str.indexOf('poor') > -1) {
    score = 'poor';
  } else {
    score = 'N/A';
  }

  return score;
};
export default calcPerformanceScore;