export const columnChartOptions = () => {
  return {
    hAxis: {
      title: '',
      textStyle: {
        fontSize: 10
      }
    },
    vAxis: {
      title: '',
      textStyle: {
        fontSize: 12
      }
    }
  };
};
export default columnChartOptions;