export default class JsUtility {
  static get isTouch() {
    return 'ontouchend' in document;
  }

  static get clickEvent() {
    return 'ontouchend' in document ? 'touchend' : 'click';
  }

  static get viewport() {
    return {
      width: window.innerWidth,
      height: window.innerHeight
    };
  }

  static get isTouchSize() {
    return window.innerWidth < 1025;
  }

  static fireEvent(name, data) {
    var event = new Event(name, {
      bubbles: true,
      cancelable: true,
      composed: true
    });
    event.detail = data;
    document.dispatchEvent(event);
    return event;
  }

  static formatCurrency(val) {
    val = parseFloat(val);
    val = val.toFixed(2);
    return val;
  }

  static formatStringCurrency(val) {
    val = parseFloat(val);
    return '$' + val.toFixed(2).toString();
  }
  /**
   *  object utilities, clones new object
   */


  static objectClone(obj) {
    var json = JSON.stringify(obj);
    return JSON.parse(json);
  }

  static sort(arr, prop) {
    var newArr = arr.slice(0);
    newArr.sort((a, b) => a[prop] > b[prop] ? 1 : -1);
    return newArr;
  }
  /**
   * array utility, returns a new array clone
   * @param {array} arr 
   */


  static arrayClone(arr) {
    return arr.slice(0);
  }
  /**
   * array utility, find item by id
   * @param {array} arr 
   * @param {string} id 
   */


  static arrayFindItemById(arr, id) {
    var length = arr.length;
    var result = null;

    for (var i = 0; i < length; i++) {
      if (arr[i]['id'] === id) {
        result = arr[i];
        break;
      }
    }

    return result;
  }

  static arrayFindItemByIdString(arr, id) {
    var length = arr.length;
    var result = null;

    for (var i = 0; i < length; i++) {
      if (arr[i]['id'].toString() === id.toString()) {
        result = arr[i];
        break;
      }
    }

    return result;
  }
  /**
   * array utility, find index of item by id
   * @param {array} arr 
   * @param {string} id 
   */


  static arrayFindIndexById(arr, id) {
    var index = -1;
    var len = arr.length;

    for (var i = 0; i < len; i++) {
      if (arr[i]['id'] === id) {
        index = i;
        break;
      }
    }

    return index;
  }
  /**
   * array utility, find index of item
   * @param {array} arr 
   * @param {object} item 
   */


  static arrayFindItemIndex(arr, item) {
    var index = -1;
    var len = arr.length;

    for (var i = 0; i < len; i++) {
      if (arr[i] === item) {
        index = i;
        break;
      }
    }

    return index;
  }
  /**
   * array utility, update existing item in array
   * @param {array} arr 
   * @param {object} item 
   */


  static arrayUpdateItem(arr, item) {
    var clone = arr.slice(0);
    var index = this.arrayFindIndexById(item.id);
    clone[index] = item;
    return clone;
  }
  /**
   * array utility, update existing item in array by index
   * @param {array} arr 
   * @param {object} item 
   * @param {int} index 
   */


  static arrayUpdateItemByIndex(arr, item, index) {
    var clone = arr.slice(0);
    clone[index] = item;
    return clone;
  }
  /**
   * array utility, delete item in array by id
   * @param {array} arr 
   * @param {string} id 
   */


  static arrayDeleteItemById(arr, id) {
    var clone = arr.slice(0);
    var index = this.arrayFindIndexById(clone, id);

    if (index > -1) {
      clone.splice(index, 1);
    }

    return clone;
  }
  /**
   * array utility, delete item from array
   * @param {array} arr 
   * @param {object} item 
   */


  static arrayDeleteItem(arr, item) {
    var clone = arr.slice(0);
    var index = this.arrayFindItemIndex(clone, item);

    if (index > -1) {
      clone.splice(index, 1);
    }

    return clone;
  }
  /**
   *  array utility, delete item in array by index
   * @param {array} arr 
   * @param {int} index 
   */


  static arrayDeleteItemByIndex(arr, index) {
    var clone = arr.slice(0);
    clone.splice(index, 1);
    return clone;
  }
  /**
   * array utility, add item to array
   * @param {array} arr 
   * @param {object} item 
   */


  static arrayAddItem(arr, item) {
    var clone = arr.slice(0);
    clone.push(item);
    return clone;
  }

  static arrayPrependElement(arr, element) {
    var clone = arr.slice(0);
    clone.unshift(element);
    return clone;
  }

  static arrayPrepend(arr, arr2) {
    var clone = arr.slice(0);
    var clone2 = arr2.slice(0);
    return clone2.concat(clone);
  }

  static arrayConcat(arr, elements) {
    var clone = arr.slice(0);
    return clone.concat(elements);
  }

  static arrayReplaceElement(arr, element) {
    var clone = arr.slice(0);
    var index = this.arrayIndexById(element.id);
    clone[index] = element;
    return clone;
  }
  /**
   *  generates a random string
   */


  static randomString(length) {
    if (!length) length = 16;
    let chars = '0123456789';
    let result = '';

    for (var i = length; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];

    return result;
  }

  static get randomId() {
    let length = 16;
    let chars = '0123456789';
    let result = '';

    for (var i = length; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];

    return result;
  }

  static getDate(value) {
    value = value.split('T');
    value = value[0];
    value += 'T12:00:00';
    var date = new Date(value);
    var day = date.getDate().toString().length > 1 ? date.getDate() : '0' + date.getDate();
    var month = date.getMonth().toString().length > 1 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1);
    var year = date.getFullYear();
    return year + '-' + month + '-' + day;
  }

  static httpRequest(url, value, method, headers, callback) {
    method = method.toLowerCase();
    let boolSendData = true;

    if (method === 'get' || method === 'delete') {
      boolSendData = false;
      if (value) url += '/' + value;
    } else if (method === 'post' || method === 'put') {
      value = JSON.stringify(value);
    }

    var req = new XMLHttpRequest();
    req.open(method, encodeURI(url));
    req.setRequestHeader('Content-Type', 'application/json');

    for (var i = 0; i < headers.length; i++) {
      req.setRequestHeader(headers[i].key, headers[i].value);
    }

    req.onload = function () {
      if (req.status === 200) {
        let data = JSON.parse(req.response);
        callback(null, data);
      } else {
        let statusText = req.statusText;
        callback({
          statusCode: req.status,
          message: statusText
        }, null);
      }
    };

    if (boolSendData) req.send(value);else req.send();
  }

  static toMobileUrl(src) {
    var split = [];
    var length = 0;
    var name = '';

    if (src.indexOf('.jpg') > -1) {
      split = src.split('.jpg');
      length = split.length;

      for (var i = 0; i < length - 1; i++) {
        name += split[i];
      }

      name += '_mobile.jpg';
      return name;
    } else if (src.indexOf('.png') > -1) {
      split = src.split('.png');
      length = split.length;

      for (var i = 0; i < length - 1; i++) {
        name += split[i];
      }

      name += '_mobile.png';
      return name;
    } else if (src.indexOf('.jpeg') > -1) {
      split = src.split('.jpeg');
      length = split.length;

      for (var i = 0; i < length - 1; i++) {
        name += split[i];
      }

      name += '_mobile.jpeg';
      return name;
    } else if (src.indexOf('.gif') > -1) {
      split = src.split('.gif');
      length = split.length;

      for (var i = 0; i < length - 1; i++) {
        name += split[i];
      }

      name += '_mobile.gif';
      return name;
    } else {
      return src;
    }
  }

}