import { LocalCache } from '../../jsx/cache/cache';
import loginData from '../data/login';
import account from '../data/account';
import Service from '../../plunk/service/service';
import Cache from '../store/cache';
export default class {
  static isAuthenticated() {
    var auth = LocalCache.get('authTokens');

    if (!auth) {
      return false;
    } else return true;
  }

  static store(tokens) {
    LocalCache.set('authTokens', tokens);
  }

  static get() {
    return LocalCache.get('authTokens');
  }

  static signIn(email, password) {
    email = email.toLowerCase();
    var login = null;
    var validLogin = false;
    var logins = loginData.get();
    logins.forEach(item => {
      if (item.email.toLowerCase() === email && item.password === password) {
        validLogin = true;
        login = item;
      }
    });

    if (validLogin && login.account) {
      account.set(login.account);
    }

    return validLogin;
  }

  static SignOut() {
    Cache.remove();
    Service.removeAllTokens();
  }

}