export const format = (data, defaultIfNull = '', places = 0, symbol = '$') => {
  if (!data) {
    return defaultIfNull;
  }

  if (typeof data === 'number') {
    if (places) {
      data = data.toFixed(places);
    }

    return symbol + data.toLocaleString("en-US");
  } else {
    return data;
  }
};
export default format;