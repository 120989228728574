export default class {
  static contact(term) {
    return '/Contacts?$where=Name.ToLower().Contains(@0)||Email.ToLower().Contains(@0)||Phone.ToLower().Contains(@0)&$params=' + term;
  }

  static product(term) {
    return '/Product?$where=Title.ToLower().Contains(@0)||Description.ToLower().Contains(@0)||Id.ToLower().StartsWith(@0)&$params=' + term;
  }

  static campaign(term) {
    return '/Campaign?$where=Name.ToLower().StartsWith(@0)&$params=' + term;
  }

  static multiActivity(term, column) {
    var route = location.pathname;
    route += `?$where=${column} like '%${term}%'`;
    return route;
  }

  static activity(term, searchFilterSelection) {
    var route = '';
    var obj = this.rangeAndGroupBy;
    var range = obj.range;
    var groupBy = obj.groupBy;

    if (range && groupBy) {
      route = `/Activity/Utm/${range}/${groupBy}?$where=${searchFilterSelection}.ToLower().Contains(@0)&$params=${term}`;
    } else if (range) {
      route = `/Activity/Utm/${range}?$where=${searchFilterSelection}.ToLower().Contains(@0)&$params=${term}`;
    } else {
      route = `/Activity/Utm/all_time?$where=${searchFilterSelection}.ToLower().Contains(@0)&$params=${term}`;
    }

    return route;
  }

  static get rangeAndGroupBy() {
    var obj = {
      range: null,
      groupBy: null
    };
    var path = window.location.pathname;

    if (path.indexOf('/Detail') > -1) {
      return obj;
    }

    path = path.replace('/Activity', '');
    path = path.replace('/Utm', '');
    var first = path[0];

    if (first === '/') {
      path = path.substring(1);
    }

    if (path) {
      let arr = path.split('/');

      if (arr[0]) {
        obj.range = arr[0];
      }

      if (arr[1]) {
        obj.groupBy = arr[1];
      }
    }

    return obj;
  }

}