export default class {
  static get() {
    return [{
      label: '$0-$15,000',
      value: '$0-$15,000'
    }, {
      label: '$15,001-$20,000',
      value: '$15,001-$20,000'
    }, {
      label: '$20,001-$30,000',
      value: '$20,001-$30,000'
    }, {
      label: '$30,001-$40,000',
      value: '$30,001-$40,000'
    }, {
      label: '$40,001-$50,000',
      value: '$40,001-$50,000'
    }, {
      label: '$50,001-$60,000',
      value: '$50,001-$60,000'
    }, {
      label: '$60,001-$75,000',
      value: '$60,001-$75,000'
    }, {
      label: '$75,001-$100,000',
      value: '$75,001-$100,000'
    }, {
      label: '$100,001-$150,000',
      value: '$100,001-$150,000'
    }, {
      label: '$150,001-$200,000',
      value: '$150,001-$200,000'
    }, {
      label: '$200,001-$250,000',
      value: '$200,001-$250,000'
    }, {
      label: '$250,001-$300,000',
      value: '$250,001-$300,000'
    }, {
      label: '$300,001-$500,000',
      value: '$300,001-$500,000'
    }, {
      label: '$500,000+',
      value: '$500,000+'
    }];
  }

}