import { LocalCache } from "../../jsx/cache/cache";
export default class {
  static get baseKey() {
    return 'activity-keyvalues';
  }

  static get key() {
    return 'activity-keyvalues-keys';
  }

  static get keyArray() {
    var key = this.key;
    var keys = LocalCache.get(key);

    if (!keys) {
      keys = [];
    }

    return keys;
  }

  static getKey(startDate, endDate) {
    var baseKey = this.baseKey;
    startDate = startDate.toString();
    endDate = endDate.toString();
    return `${baseKey}:${startDate}:${endDate}`;
  }

  static get(startDate, endDate) {
    var key = this.getKey(startDate, endDate);
    var result = LocalCache.get(key);
    return result;
  }

  static save(model, startDate, endDate) {
    var key = this.getKey(startDate, endDate);
    LocalCache.set(key, model);
    this.saveKey(key);
  }

  static saveKey(modelKey) {
    var keyArray = this.keyArray;

    if (!keyArray.includes(key)) {
      keyArray.push(modelKey);
      var key = this.key;
      LocalCache.set(key, keyArray);
    }
  }

  static saveColumnDisplay(columnDisplay) {
    var keyArray = this.keyArray;
    keyArray.forEach(key => {
      var model = LocalCache.get(key);
      model.columnDisplay = columnDisplay;
      LocalCache.set(key, model);
    });
  }

  static remove() {
    var keyArray = this.keyArray;
    keyArray.forEach(key => {
      LocalCache.delete(key);
    });
    let key = this.key;
    LocalCache.delete(key);
  }

}