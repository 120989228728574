export const snakeToCamel = str => {
  // Split the string into an array of words using the underscore delimiter
  const words = str.split('_'); // Capitalize the first letter of each word after the first word

  const camelWords = words.map((word, index) => {
    if (index === 0) {
      return word;
    }

    return word.charAt(0).toUpperCase() + word.slice(1);
  }); // Join the words together to form the camelCase string

  const camelStr = camelWords.join('');
  return camelStr;
};
export default snakeToCamel;