export const lineChartOptions = () => {
  return {
    hAxis: {
      title: ''
    },
    vAxis: {
      title: ''
    },
    series: {
      1: {
        curveType: 'function'
      }
    }
  };
};
export default lineChartOptions;