export const interests = [{
  label: 'home improvement',
  value: 'home improvement'
}, {
  label: 'electronics',
  value: 'electronics'
}, {
  label: 'home decoration',
  value: 'home decoration'
}, {
  label: 'reading',
  value: 'reading'
}, {
  label: 'cooking',
  value: 'cooking'
}, {
  label: 'travel',
  value: 'travel'
}, {
  label: 'sports',
  value: 'sports'
}, {
  label: 'exercise',
  value: 'exercise'
}, {
  label: 'investing',
  value: 'investing'
}, {
  label: 'gourmet cooking',
  value: 'gourmet cooking'
}, {
  label: 'music',
  value: 'music'
}, {
  label: 'movies',
  value: 'movies'
}, {
  label: 'crafts',
  value: 'crafts'
}, {
  label: 'collecting',
  value: 'collecting'
}, {
  label: 'outdoors',
  value: 'outdoors'
}, {
  label: 'kids',
  value: 'kids'
}, {
  label: 'fitness',
  value: 'fitness'
}, {
  label: 'health',
  value: 'health'
}, {
  label: 'automobiles',
  value: 'automobiles'
}, {
  label: 'gardening',
  value: 'gardening'
}, {
  label: 'medicine',
  value: 'medicine'
}];