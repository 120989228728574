function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React from 'react';
import PubSub from 'pubsub-js';
import Utility from '../../jsx/utility/utility';
import Auth from '../functions/authenticate';
export class BaseGridViewComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  get query() {
    var url = location.href;
    var query = {};
    var hu = url.split('?')[1];

    if (typeof hu !== 'undefined') {
      var gy = hu.split("&");

      for (var i = 0; i < gy.length; i++) {
        var ft = gy[i].split("=");
        query[ft[0]] = ft[1];
      }
    }

    return query;
  }

  get queryParams() {
    var search = location.search;
    search = search.replace('?', '');
    let params = new URLSearchParams(search);
    return params;
  }

  get page() {
    var page = this.query.page;
    if (!page) page = 1;
    page = parseInt(page);
    return page;
  }

  compare(page) {
    return page === this.refreshUrl;
  }

  gotoPage(page) {
    PubSub.publish('allow-update', {});
    setTimeout(() => {
      this.props.history.push(page);
    }, 100);
  }

}
export class BaseDataViewComponent extends React.Component {
  constructor(props) {
    super(props);

    _defineProperty(this, "subscriber", () => {
      PubSub.subscribe('search', (msg, data) => {
        this.allowUpdate = false;
      });
      PubSub.subscribe('allow-update', (msg, data) => {
        this.allowUpdate = true;
      });
    });

    this.allowUpdate = true;
    this.allowRender = false;
    this.error = false;
    this.errorMessage = null;
  }

  componentDidMount() {
    this.subscriber();
    Utility.fireEvent('app-layout-close-drawer', {});

    if (Auth.isAuthenticated()) {
      this.allowUpdate = false;
      this.fetchData();
    } else {
      location.href = '/Auth/SignIn';
    }
  }

  componentDidUpdate() {
    if (this.allowUpdate) {
      this.allowUpdate = false;
      this.fetchData();
    } else {
      this.allowUpdate = true;
    }
  }

  get query() {
    var url = location.href;
    var query = {};
    var hu = url.split('?')[1];

    if (typeof hu !== 'undefined') {
      var gy = hu.split("&");

      for (var i = 0; i < gy.length; i++) {
        var ft = gy[i].split("=");
        query[ft[0]] = ft[1];
      }
    }

    return query;
  }

  get queryParams() {
    var search = location.search;
    search = search.replace('?', '');
    let params = new URLSearchParams(search);
    return params;
  }

  get page() {
    var query = this.queryParams;
    var page = query.get('page');
    if (!page) page = 1;
    page = parseInt(page);
    return page;
  }

}
export class BaseDataViewComponentAsync extends React.Component {
  constructor(props) {
    super(props);

    _defineProperty(this, "subscriber", () => {
      PubSub.subscribe('search', (msg, data) => {
        this.allowUpdate = false;
      });
      PubSub.subscribe('allow-update', (msg, data) => {
        this.allowUpdate = true;
      });
    });

    this.allowUpdate = true;
    this.allowRender = false;
    this.error = false;
    this.errorMessage = null;
  }

  async componentDidMount() {
    this.subscriber();

    if (Auth.isAuthenticated()) {
      this.allowUpdate = false;
      await this.fetchData();
    } else {
      location.href = '/Auth/SignIn';
    }
  }

  async componentDidUpdate() {
    console.log('update');
    var allow = this.allowUpdate;
    console.log(allow);

    if (this.allowUpdate) {
      this.allowUpdate = false;
      await this.fetchData();
    } else {
      this.allowUpdate = true;
    }
  }

  get query() {
    var url = location.href;
    var query = {};
    var hu = url.split('?')[1];

    if (typeof hu !== 'undefined') {
      var gy = hu.split("&");

      for (var i = 0; i < gy.length; i++) {
        var ft = gy[i].split("=");
        query[ft[0]] = ft[1];
      }
    }

    return query;
  }

  get queryParams() {
    var search = location.search;
    search = search.replace('?', '');
    let params = new URLSearchParams(search);
    return params;
  }

  get page() {
    var query = this.queryParams;
    var page = query.get('page');
    if (!page) page = 1;
    page = parseInt(page);
    return page;
  }

}
export default {
  BaseDataViewComponent,
  BaseGridViewComponent,
  BaseDataViewComponentAsync
};