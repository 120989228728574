export default class {
  static dispayCount(columnDisplay) {
    var count = 0;

    for (const [key, value] of Object.entries(columnDisplay)) {
      if (value) {
        count += 1;
      }
    }

    return count;
  }

  static reset(columnDisplay) {
    for (const [key, value] of Object.entries(columnDisplay)) {
      columnDisplay[key] = false;
    }

    return columnDisplay;
  }

}