export class Utility {
  static get viewport() {
    return {
      width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
      height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
    };
  }

  static get touch() {
    return 'ontouchend' in document;
  }

  static get browser() {
    var b = {
      chrome: false,
      firefox: false,
      safari: false,
      ie: false,
      edge: false,
      other: false
    };

    if (window.chrome) {
      b.chrome = true;
    } else if (typeof InstallTrigger !== 'undefined') {
      b.firefox = true;
    } else if (/constructor/i.test(window.HTMLElement) || function (p) {
      return p.toString() === "[object SafariRemoteNotification]";
    }(!window['safari'] || typeof safari !== 'undefined' && safari.pushNotification)) {
      b.safari = true;
    } else if (/Edge\/12./i.test(navigator.userAgent)) {
      b.edge = true;
    } else if (document.documentMode) {
      b.ie = true;
    } else {
      b.other = true;
    }

    return b;
  }

  static get device() {
    var d = {
      touch: 'ontouchend' in document,
      android: false,
      iphone: false,
      ipad: false,
      ios: false,
      smartphone: false,
      tablet: false,
      retina: false,
      width: null
    };

    if (/Android/.test(navigator.userAgent)) {
      d.android = true;
    } else if (/iPhone/.test(navigator.userAgent)) {
      d.iphone = true;
    } else if (/iPad/.test(navigator.userAgent)) {
      d.ipad = true;
    }

    if (d.iphone || d.ipad) {
      d.ios = true;
    }

    d.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    if (d.touch) {
      if (d.width < 640) {
        d.smartphone = true;
      } else {
        d.tablet = true;
      }

      if (window.devicePixelRatio > 1) {
        d.retina = true;
      }
    }

    return d;
  }

  static get support() {
    return {
      serviceworker: 'serviceWorker' in navigator,
      webworker: 'Worker' in window,
      geolocation: 'geolocation' in navigator,
      pushnotification: 'PushManager' in window
    };
  }

  static get clickEvent() {
    return 'ontouchend' in document ? 'touchend' : 'click';
  }

  static async downloadAsync(s) {
    return new Promise((resolve, reject) => {
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = s;

      script.onload = function () {
        resolve(true);
      };

      document.head.appendChild(script);
    });
  }

  static async loadScriptsAsync(scripts) {
    for (var i = 0; i < scripts.length; i++) {
      var script = scripts[i];
      var result = await this.downloadAsync(script);
    }
  }

  static formDataToObject(formData) {
    var obj = {};
    formData.forEach((value, key) => {
      obj[key] = value;
    });
    return obj;
  }

  static getBlobUrl(url) {
    const content = `importScripts( "${url}" );`;
    return URL.createObjectURL(new Blob([content], {
      type: "text/javascript"
    }));
  }

  static get baseUrl() {
    return location.protocol + '//' + location.host + location.pathname;
  }

  static isEmpty(obj) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  get viewport() {
    return this.constructor.viewport;
  }

  get touch() {
    return this.constructor.touch;
  }

  get browser() {
    return this.constructor.browser;
  }

  get device() {
    return this.constructor.device;
  }

  get support() {
    return this.constructor.support;
  }

  get clickEvent() {
    return this.constructor.clickEvent;
  }

  get baseUrl() {
    return this.constructor.baseUrl;
  }

  getBlobUrl(url) {
    return this.constructor.getBlobUrl(url);
  }

  formDataToObject(formData) {
    return this.constructor.formDataToObject(formData);
  }

  async loadScriptsAsync(scripts) {
    return this.constructor.loadScriptsAsync(scripts);
  }

}