function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import Typed from 'typed.js';
import { LocalCache } from '../../jsx/cache/cache';
export default class {
  constructor() {
    _defineProperty(this, "run", (container, tenantId, assistantId, apiRoot, welcomeMessage, aiService) => {
      this.X_TENANT_ID = tenantId;
      this.CHAT_INTERFACE = container;
      this.X_ASSISTANT_ID = assistantId;
      this.API_ROOT = apiRoot;
      this.CHAT_CACHE_KEY = `plunk-assistant-${this.X_ASSISTANT_ID}`;
      this.CHAT_WELCOME_MESSAGE = welcomeMessage;
      this.MESSAGE_CHAT_TIMEOUT_ERROR = aiService + ' Timeout Error';

      window.onChatImageError = t => {
        if (this.ACTION_APPLY_IMAGE_404_HANDLER) {
          let imgId = t.dataset.id;
          let containerId = 'gallery-item-contains-' + imgId;
          var galleryItem = document.querySelector("#" + containerId);
          galleryItem.classList.add('p-hide');
        }
      };

      var skeleton = this.templateSkeleton();
      this.CHAT_INTERFACE.insertAdjacentHTML('beforeend', skeleton);
      this.setupApp();
    });

    _defineProperty(this, "setupApp", () => {
      var responses = [];
      var history = this.getChatHistory();

      if (!history) {
        this.USER_ID = this.guid();
        this.apiPostChat((err, data) => {
          if (!err) {
            this.CHAT_SESSION_ID = data.id;
            let greeting = 'Hello, nice to meet you! How can I help you today?';
            let defaultAnswer = this.formatDefaultAnswer(greeting);
            let type = 'content';
            this.saveResponse(this.CHAT_SESSION_ID, null, type, null, defaultAnswer, null, true); //chatId, question = null, apiResponse=null,answer = null, footer = null, initialResponse = false

            let initResponse = this.responseModel(this.CHAT_SESSION_ID, null, type, null, defaultAnswer, null, true);
            responses = [initResponse];
            this.setupChatAppSelectorsAndListeners();
            var history = this.templateHistory(responses);
            var html = history.html;
            this.CHAT_RESPONSES_CONTAINER.insertAdjacentHTML('beforeend', html);
          } else {
            //handle error
            console.log('error');
            this.CHAT_LOADER = document.querySelector('#p-chat-loading-container');
            setTimeout(() => {
              this.CHAT_LOADER.remove();
              this.CHAT_RESPONSES_CONTAINER = document.querySelector('#p-topic-responses-container');
              this.CHAT_MAIN_CONTENT_CONTAINER = document.querySelector('#p-main-content-container');
              this.CHAT_MAIN_CONTENT_CONTAINER.classList.remove('p-hide');
              let error = `<br><br><br><span class="p-status">${this.MESSAGE_CHAT_CREATE_ERROR}</span>`;
              this.CHAT_RESPONSES_CONTAINER.insertAdjacentHTML('beforeend', error);
            }, 2000);
          }
        });
      } else {
        this.USER_ID = history.userId;
        this.CHAT_SESSION_ID = history.chatId;
        responses = history.responses;
        this.setupChatAppSelectorsAndListeners();
        var history = this.templateHistory(responses);
        var html = history.html;
        var footerActions = history.footerActions;
        this.CHAT_RESPONSES_CONTAINER.insertAdjacentHTML('beforeend', html);

        if (footerActions.length > 0) {
          footerActions.forEach(item => {
            let elementId = item.elementId;
            let element = document.querySelector('#' + elementId);

            if (element) {
              element.addEventListener('click', event => {
                this.onNewQuestion(item.question);
              });
            }
          });
        }
      }
    });

    _defineProperty(this, "setupChatAppSelectorsAndListeners", () => {
      this.CHAT_TEXTAREA = document.querySelector('#p-chat-textarea');
      this.CHAT_MAIN_CONTENT_CONTAINER = document.querySelector('#p-main-content-container');
      this.CHAT_GENERATING = document.querySelector('#p-chat-generating');
      this.CHAT_GENERATING_TEXT = document.querySelector('#p-chat-generating-text');
      this.CHAT_RESPONSES_CONTAINER = document.querySelector('#p-topic-responses-container');
      this.CHAT_SUBMIT_ICON = document.querySelector('#p-chat-submit-icon');
      this.CHAT_TEXTAREA.addEventListener('keydown', this.onTextAreaKeyDown);
      this.CHAT_TEXTAREA.addEventListener('keyup', this.onTextAreaKeyUp);
      this.CHAT_SUBMIT_ICON.addEventListener('click', this.onSubmitIconClick);
      this.CHAT_LOADER = document.querySelector('#p-chat-loading-container');
      this.CHAT_FOOTER = document.querySelector('#p-chat-footer');
      this.CHAT_CONTAINER = document.querySelector('#p-main-content-container');
      this.CHAT_NEW_TOPIC_BUTTON = document.querySelector('#p-chat-new-topic-button');

      if (this.CHAT_NEW_TOPIC_BUTTON) {
        this.CHAT_NEW_TOPIC_BUTTON.addEventListener('click', this.onNewTopic);
      }

      setTimeout(() => {
        this.CHAT_LOADER.remove();
        this.CHAT_CONTAINER.classList.remove('p-hide');
        this.CHAT_FOOTER.classList.remove('p-hide');
        this.CHAT_MAIN_CONTENT_CONTAINER.scrollTop = this.CHAT_MAIN_CONTENT_CONTAINER.scrollHeight;
        console.log('scrolled');
      }, 2000);
    });

    _defineProperty(this, "onTextAreaKeyDown", event => {
      var value = event.target.value;

      if (event.keyCode === 13) {
        event.preventDefault();
        this.handlePromptClick(value);
      } else if (value) {
        this.CHAT_SUBMIT_ICON.classList.add('p-active');
      } else {
        this.CHAT_SUBMIT_ICON.classList.remove('p-active');
      }
    });

    _defineProperty(this, "onTextAreaKeyUp", event => {
      var value = event.target.value;

      if (value) {
        this.CHAT_SUBMIT_ICON.classList.add('p-active');
      } else {
        this.CHAT_SUBMIT_ICON.classList.remove('p-active');
      }
    });

    _defineProperty(this, "onSubmitIconClick", event => {
      var value = this.CHAT_TEXTAREA.value;
      this.handlePromptClick(value);
    });

    _defineProperty(this, "handlePromptClick", value => {
      this._postNewQuestion(value);
    });

    _defineProperty(this, "onNewQuestion", question => {
      this._postNewQuestion(question);
    });

    _defineProperty(this, "onNewTopic", event => {
      if (!this.CHAT_IN_PROGRESS) {
        this.CHAT_IN_PROGRESS = true;
        this.CHAT_GENERATING.classList.add('visible');
        this.CHAT_MAIN_CONTENT_CONTAINER.scrollTop = this.CHAT_MAIN_CONTENT_CONTAINER.scrollHeight;
        this.CHAT_TEXTAREA.value = '';
        this.CHAT_SUBMIT_ICON.classList.remove('p-active');
        this.CHAT_GENERATING_TEXT.innerHTML = 'Creating New Chat...';
        console.log('new chat');
        this.apiPostChat((err, data) => {
          console.log('new chat result');
          this.CHAT_GENERATING.classList.remove('visible');
          this.CHAT_GENERATING_TEXT.innerHTML = 'Generating...';
          this.CHAT_RESPONSES_CONTAINER.innerHTML = '';
          this.CHAT_SUBMIT_ICON.classList.add('p-active');
          this.CHAT_IN_PROGRESS = false;

          if (!err) {
            LocalCache.delete(this.CHAT_CACHE_KEY);
            this.CHAT_SESSION_ID = data.id;
            let greeting = 'Hello, nice to meet you! How can I help you today?';
            let defaultAnswer = this.formatDefaultAnswer(greeting);
            let type = 'content';
            this.saveResponse(this.CHAT_SESSION_ID, null, type, null, defaultAnswer, null, true);
            let initResponse = this.responseModel(this.CHAT_SESSION_ID, null, type, null, defaultAnswer, null, true);
            let responses = [initResponse];
            var history = this.templateHistory(responses);
            var html = history.html;
            this.CHAT_RESPONSES_CONTAINER.insertAdjacentHTML('beforeend', html);
          } else {
            //handle error
            console.log('error');
            let errMessage = '';
            if (err.message) errMessage = err.message;
            let error = `<br><br><br><span class="p-error p-large">Error creating chat...${errMessage}</span>`;
            this.CHAT_RESPONSES_CONTAINER.insertAdjacentHTML('beforeend', error);
          }
        });
      }
    });

    _defineProperty(this, "_postNewQuestion", value => {
      if (value && !this.CHAT_IN_PROGRESS) {
        this.CHAT_IN_PROGRESS = true;
        this.CHAT_GENERATING.classList.add('visible');
        this.CHAT_MAIN_CONTENT_CONTAINER.scrollTop = this.CHAT_MAIN_CONTENT_CONTAINER.scrollHeight;
        this.CHAT_TEXTAREA.value = '';
        this.CHAT_SUBMIT_ICON.classList.remove('p-active');
        this.generateResponse(value);
      }
    });

    _defineProperty(this, "getHeaders", () => {
      let headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('X-ACCOUNT-ID', this.X_TENANT_ID);
      headers.append('X-APP-ID', this.X_TENANT_ID);
      headers.append('X-ASSISTANT-ID', this.X_ASSISTANT_ID);
      headers.append('X-SESSION-ID', this.CHAT_SESSION_ID);
      return headers;
    });

    _defineProperty(this, "getChatHistory", () => {
      var history = LocalCache.get(this.CHAT_CACHE_KEY);
      return history; //return getTestHistory();
    });

    _defineProperty(this, "apiPostChat", callback => {
      var options = {
        method: 'POST',
        headers: this.getHeaders()
      };
      this.send(this.API_POST_CHAT, options, callback);
    });

    _defineProperty(this, "apiPostChatQuestion", (question, callback) => {
      var API_POST_CHAT_QUESTION = this.API_ROOT + '/' + this.CHAT_SESSION_ID + '/messages';
      var payload = this.payloadPostQuestionModel(question);
      var body = JSON.stringify(payload);
      var options = {
        method: 'POST',
        headers: this.getHeaders(),
        body: body
      };

      if (!this.TEST_RESPONSE_MODE) {
        this.send(API_POST_CHAT_QUESTION, options, callback);
      } else {
        this.getTestResponse(callback);
      }
    });

    _defineProperty(this, "apiGetChatRunStatus", (runId, callback) => {
      var API_GET_CHAT_RUN_STATUS = this.API_ROOT + '/runs/' + runId;
      this.CHAT_RUN_ID = runId;
      var options = {
        method: 'GET',
        headers: this.getHeaders()
      };
      this.send(API_GET_CHAT_RUN_STATUS, options, callback);
    });

    _defineProperty(this, "apiGetChatRunResult", callback => {
      var API_GET_CHAT_RESPONSE = this.API_ROOT + '/' + this.CHAT_SESSION_ID + '/messages';
      var options = {
        method: 'GET',
        headers: this.getHeaders()
      };
      this.send(API_GET_CHAT_RESPONSE, options, callback);
    });

    _defineProperty(this, "send", (url, options, callback) => {
      var Ok, status, statusText;
      let errObj = {
        message: null,
        statusCode: null
      };
      fetch(url, options).then(response => {
        Ok = response.ok;
        status = response.status;
        statusText = response.statusText;
        return response;
      }).then(response => response.text()).then(text => {
        console.log(text);

        if (Ok || status === 201) {
          try {
            let data = JSON.parse(text);
            callback(null, data);
          } catch (ex) {
            callback(null, text);
          }
        } else {
          errObj.statusCode = status;
          errObj.message = statusText;
          callback(errObj, null);
        }
      }).catch(err => {
        if (typeof err === 'string') statusText = err;
        errObj.statusCode = status;
        errObj.message = statusText;
        callback(errObj, null);
      });
    });

    _defineProperty(this, "saveResponse", (chatId, question = null, type = null, apiResponse = null, answer = null, footer = null, initialResponse = false) => {
      var model = this.responseModel(chatId, question, type, apiResponse, answer, footer, initialResponse);
      var history = LocalCache.get(this.CHAT_CACHE_KEY);
      console.log(history);

      if (!history) {
        history = this.cacheModel();
      }

      var responses = history.responses;
      responses.push(model);
      history.responses = responses;
      LocalCache.set(this.CHAT_CACHE_KEY, history);
    });

    _defineProperty(this, "responseModel", (chatId, question = null, type = null, apiResponse = null, answer = null, footer = null, initialResponse = false) => {
      return {
        chatId: chatId,
        question: question,
        type: type,
        answer: answer,
        apiResponse: apiResponse,
        footer: footer,
        dataStamp: Date.now(),
        formattedDate: this.formattedDate(),
        order: null,
        initialResponse: initialResponse
      };
    });

    _defineProperty(this, "cacheModel", (responses = []) => {
      return {
        chatId: this.CHAT_SESSION_ID,
        userId: this.USER_ID,
        dateStamp: Date.now(),
        responses: responses
      };
    });

    _defineProperty(this, "payloadPostQuestionModel", question => {
      return {
        message: question,
        userId: '',
        userName: ''
      };
    });

    _defineProperty(this, "contentAnswerModel", (id, question, type, answer) => {
      let content = [];
      let footer = [];
      var footerAction = null;
      console.log('content answer model');
      answer.forEach(obj => {
        if (obj.type === 'TEXT') {
          let value = obj.value;
          value = value.replace(/&/g, '&amp;');
          content.push({
            value: value,
            typeOut: true
          });
        } else if (obj.type === 'BRACKET') {
          let value = obj.value;
          let text = value.text;
          text = text.replace(/&/g, '&amp;');
          let a = `&nbsp;<a class="p-link" href="${value.link}" target="_blank">${text}</a>&nbsp;`;
          content.push({
            value: a,
            typeOut: true
          });
          let f = `<a href="${value.link}" target="_blank">${text}</a>`;
          let footerElement = {
            type: 'BRACKET',
            text: text,
            content: f
          };
          footer = this._veryFooterAddition(footer, footerElement);
        } else if (obj.type === 'OBJECT') {
          let value = obj.value;
          let title = value.name;
          let link = value.url;
          let response = value.response;
          let a = null;

          if (title && link) {
            if (title) title = title.replace(/&/g, '&amp;');
            a = `&nbsp;<a class="p-link" href="${link}" target="_blank">${title}</a>&nbsp;`;
            content.push({
              value: a,
              typeOut: true
            });
          } else if (response) {
            content.push({
              value: response,
              typeOut: true
            });
          }

          if (type === 'store-location' && response) {
            let response = value.response;
            response = response.replace(/&/g, '&amp;');
            content.push({
              value: response,
              typeOut: true
            });
            let f = `<a href="${link}" target="_blank">${title}</a>`;
            let footerElement = {
              type: 'BRACKET',
              text: title,
              content: f
            };
            footer = this._veryFooterAddition(footer, footerElement);
          }
        } else if (obj.type === 'ARRAY') {
          var templateString = '';

          if (obj.isImageGrid) {
            templateString = this.templateGallery(id, obj.value);
          } else {
            templateString = this.templateArray(id, obj.value);
          }

          if (!footerAction && obj.action) {
            let elementId = 'action' + this.randomId(6);
            let element = `<a id="${elementId}>${obj.action}</a>`;
            footerAction = {
              elementId: elementId,
              content: element,
              question: 'I would like to ' + obj.action.toLowerCase() + ' for ' + question
            };
            let footerElement = {
              type: 'ACTION',
              text: '',
              content: element
            };
            footer = this._veryFooterAddition(footer, footerElement);
          }

          content.push({
            value: templateString,
            typeOut: false
          });
        }
      });
      footer.sort((a, b) => a.type > b.type ? 1 : -1);
      let footerContent = '';
      footer.forEach(obj => {
        footerContent += `
               ${obj.content}
             `;
      });
      let history = '';
      content.forEach(item => {
        history += `
              ${item.value}
            `;
      });
      return {
        content: content,
        footer: footerContent,
        footerAction: footerAction,
        history: history
      };
    });

    _defineProperty(this, "_veryFooterAddition", (footer, element) => {
      var len = footer.length;

      if (len < 2) {
        var isBracketDuplicate = this._verifyFooterNoBracketDuplicates(footer, element);

        if (!isBracketDuplicate) {
          footer.push(element);
        }

        return footer;
      } else if (len === 2 && element.type === 'ACTION') {
        let item = footer[0];
        let newFooter = [];
        newFooter.push(element);
        newFooter.push(item);
        return newFooter;
      } else {
        return footer;
      }
    });

    _defineProperty(this, "_verifyFooterNoBracketDuplicates", (footer, element) => {
      var len = footer.length;
      var isDuplicate = false;

      for (var i = 0; i < len; i++) {
        var item = footer[i];

        if (item.type === 'BRACKET' && element.type === 'BRACKET' && item.text === element.text) {
          isDuplicate = true;
        }
      }

      return isDuplicate;
    });

    _defineProperty(this, "_footerHasAction", footer => {
      var len = footer.length;
      var hasAction = false;

      for (var i = 0; i < len; i++) {
        var item = footer[i];

        if (item[i].type === 'ACTION') {
          hasAction = true;
        }
      }

      return hasAction;
    });

    _defineProperty(this, "generateResponse", question => {
      var id = this.randomId(6);
      var response = this.templateResponse(id, question);
      this.CHAT_RESPONSES_CONTAINER.insertAdjacentHTML('beforeend', response);
      this.CHAT_MAIN_CONTENT_CONTAINER.scrollTop = this.CHAT_MAIN_CONTENT_CONTAINER.scrollHeight;
      this.apiPostChatQuestion(question, (err, result) => {
        console.log(err);
        console.log(result);

        if (!err) {
          if (!this.TEST_RESPONSE_MODE) {
            this._pollChatRunStatus(result.runId, id, question);
          } else {
            this._generateCompletedResponse(id, question, result);
          }
        } else {
          var answerContainer = document.querySelector('#answer-container-' + id);
          var answerCheck = document.querySelector('#answer-check-' + id);
          var answerLabel = answerCheck.querySelector('.p-answer-check-label');
          answerCheck.classList.add('p-status');
          answerLabel.innerHTML = this.MESSAGE_CHAT_POST_ERROR;
          var svg = answerCheck.querySelector('svg');
          svg.classList.add('p-hide');
          answerContainer.classList.add('p-hide');
          this.CHAT_IN_PROGRESS = false;
          this.CHAT_GENERATING.classList.remove('visible');
        }
      });
    });

    _defineProperty(this, "_generateCompletedResponse", (id, question, data) => {
      //display 'generating message'
      this.CHAT_GENERATING.classList.add('visible'); //parse api response

      var response = this.parseApiResponseV2(data);
      var type = response.type; //format response into answer model

      let answer = null;

      if (response.v2) {
        answer = this.formatAnswerFromResponseV2(response.response);
      } else {
        answer = this.formatAnswerFromResponse(response.response);
      } //save response


      if (!this.TEST_RESPONSE_MODE) {
        this.saveResponse(this.CHAT_SESSION_ID, question, type, data, answer, null, false);
      } //hide cursor


      var cursor = document.querySelector('#cursor-' + id);
      cursor.classList.add('p-hide'); //get answer element

      var answerId = '#answer-' + id;
      var answerEle = document.querySelector(answerId); //get answer content rendering model

      var answerModel = this.contentAnswerModel(id, question, type, answer); // OBJECT

      var content = answerModel.content; //ARRAY

      var footer = answerModel.footer; //ARRAY

      var footerAction = answerModel.footerAction; //OBJECT
      //scroll top

      this.CHAT_MAIN_CONTENT_CONTAINER.scrollTop = this.CHAT_MAIN_CONTENT_CONTAINER.scrollHeight; // asynchronously iterate rendering content parts

      this.forEachPromise(content, answerEle, this._handleContentResponse).then(() => {
        //done asynchronously rendering answer
        //accept new prompts
        this.CHAT_IN_PROGRESS = false; //remove 'generating'

        this.CHAT_GENERATING.classList.remove('visible'); //scroll top

        this.CHAT_MAIN_CONTENT_CONTAINER.scrollTop = this.CHAT_MAIN_CONTENT_CONTAINER.scrollHeight; //finally, render footer

        this._insertFooter(id, footer, footerAction);
      });
    });

    _defineProperty(this, "_generateErrorResponse", (id, message) => {
      var answerContainer = document.querySelector('#answer-container-' + id);
      var answerCheck = document.querySelector('#answer-check-' + id);
      var answerLabel = answerCheck.querySelector('.p-answer-check-label');
      answerCheck.classList.add('p-status');
      answerLabel.innerHTML = message;
      var svg = answerCheck.querySelector('svg');
      svg.classList.add('p-hide');
      answerContainer.classList.add('p-hide');
      this.CHAT_GENERATING.classList.remove('visible');
      this.CHAT_IN_PROGRESS = false;
    });

    _defineProperty(this, "_handleContentResponse", (item, answerEle) => {
      return new Promise((resolve, reject) => {
        if (item.typeOut) {
          this._typeContentResponse(item.value, answerEle, () => {
            resolve();
          });
        } else {
          this._insertContentResponse(item.value, answerEle, () => {
            resolve();
          });
        }
      });
    });

    _defineProperty(this, "_typeContentResponse", (content, answerEle, callback) => {
      let typeId = 'type-' + this.randomId(6);
      let div = `<div class="p-inline" id="${typeId}"></div>`;
      answerEle.insertAdjacentHTML('beforeend', div);
      var typed = new Typed('#' + typeId, {
        strings: [content],
        typeSpeed: 5,
        showCursor: false,
        onComplete: () => {
          this.CHAT_MAIN_CONTENT_CONTAINER.scrollTop = this.CHAT_MAIN_CONTENT_CONTAINER.scrollHeight;
          callback();
        }
      });
    });

    _defineProperty(this, "_insertContentResponse", (content, answerEle, callback) => {
      answerEle.insertAdjacentHTML('beforeend', content);
      this.CHAT_MAIN_CONTENT_CONTAINER.scrollTop = this.CHAT_MAIN_CONTENT_CONTAINER.scrollHeight;
      callback();
    });

    _defineProperty(this, "_insertFooter", (id, footer, footerAction) => {
      if (footer && footer !== '') {
        var footerEle = document.querySelector('#footerLink-' + id);
        footerEle.insertAdjacentHTML('beforeend', footer);
      }

      if (footerAction) {
        let elementId = footerAction.elementId;
        let element = document.querySelector('#' + elementId);

        if (element) {
          element.addEventListener('click', event => {
            this.onNewQuestion(footerAction.question);
          });
        }
      }
    });

    _defineProperty(this, "_pollChatRunStatus", (runId, id, question) => {
      setTimeout(() => {
        this.apiGetChatRunStatus(runId, (err, data) => {
          if (!err) {
            if (data.status === 'Completed') {
              this._completeChatResponse(id, question);
            } else if (data.status === 'Failed') {
              this._generateErrorResponse(id, this.MESSAGE_CHAT_RUN_ERROR);
            } else if (data.status === 'Timeout') {
              this._generateErrorResponse(id, this.MESSAGE_CHAT_TIMEOUT_ERROR);
            } else {
              this._pollChatRunStatus(runId, id, question);
            }
          } else {
            this._generateErrorResponse(id, this.MESSAGE_CHAT_POST_ERROR);
          }
        });
      }, this.CHAT_RUN_POLL_INTERVAL);
    });

    _defineProperty(this, "_completeChatResponse", (id, question) => {
      this.apiGetChatRunResult((err, data) => {
        if (!err) {
          this._generateCompletedResponse(id, question, data);
        } else {
          this._generateErrorResponse(id, this.MESSAGE_CHAT_POST_ERROR);
        }
      });
    });

    _defineProperty(this, "_findArrayContentToRender", (order, array, index) => {
      var arrayContent = [];

      for (let i = order.length - 1; i >= 0; i--) {
        if (i < index) {
          let item = order[i];

          if (item.contentIndex < -1) {
            let arrayItem = this.arrayFindByIdentifier(array, 'index', item.index);
            arrayContent.push(arrayItem.content);
          } else {
            break;
          }
        }
      }

      return arrayContent;
    });

    _defineProperty(this, "templateResponse", (id, question) => {
      var responseId = 'reponse-' + id;
      var answerid = 'answer-' + id;
      var cursorId = 'cursor-' + id;
      var footerId = 'footer-' + id;
      var footerLinkId = 'footerLink-' + id;
      var footerTimeStampId = 'footerTimeStamp-' + id;
      var answerContainerId = 'answer-container-' + id;
      var answerCheckId = 'answer-check-' + id;
      var html = `<div class="p-topic-response" id="${responseId}">
                            <div class="p-topic-question">
                                ${question}
                            </div>
                            <div class="p-answer-check" id="${answerCheckId}">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" height="28" width="28">
                                    <path d="M256 1088q0-26 19-45t45-19q26 0 45 19l403 402 915-914q19-19 45-19t45 19 19 45q0 26-19 45l-960 960q-19 19-45 19t-45-19l-448-448q-19-19-19-45z"></path>
                                </svg>
                                <div class="p-answer-check-label">
                                    Generating an answer for you...
                                </div>
                            </div>
                            <div class="p-topic-answer-container" id="${answerContainerId}">
                                <div class="p-topic-answer">
                                   <span class="p-chat-typing-cursor" id="${cursorId}">|</span>
                                   <div class="p-topic-answer-content" id="${answerid}"></div>
                                </div>
                                <div class="p-topic-answer-footer" id="${footerId}">
                                   <div id="${footerLinkId}"></div>
                                   <div id="${footerTimeStampId}" class="p-timestamp"><span>${this.formattedDate()}</span><span class="p-indicator"></span></div>
                                </div>
                            </div>
                        </div>`;
      return html;
    });

    _defineProperty(this, "templateHistory", responses => {
      var html = '';
      var footerActions = [];
      responses.forEach(response => {
        var generatedAnswer = 'Generated answer...';

        if (!response.question) {
          generatedAnswer = 'Greeting...';
        }

        let id = this.randomId(6);
        let responseId = 'reponse-' + id;
        let answerid = 'answer-' + id;
        let footerId = 'footer-' + id;
        let answerContainerId = 'answer-container-' + id;
        let answerCheckId = 'answer-check-' + id;
        let question = response.question;
        let answer = response.answer;
        let type = response.type;
        let answerModel = this.contentAnswerModel(id, question, type, answer);
        let answerContent = answerModel.history;
        let footerContent = answerModel.footer;
        let footerAction = answerModel.footerAction;

        if (footerAction) {
          footerActions.push(footerAction);
        }

        html += `<div class="p-topic-response" id="${responseId}">`;

        if (question) {
          html += `<div class="p-topic-question">
                    ${question}
                </div>`;
        }

        html += `<div class="p-answer-check" id="${answerCheckId}">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048" height="28" width="28">
                                    <path d="M256 1088q0-26 19-45t45-19q26 0 45 19l403 402 915-914q19-19 45-19t45 19 19 45q0 26-19 45l-960 960q-19 19-45 19t-45-19l-448-448q-19-19-19-45z"></path>
                                </svg>
                                <div class="p-answer-check-label">
                                    ${generatedAnswer}
                                </div>
                            </div>`;
        html += `<div class="p-topic-answer-container" id="${answerContainerId}">`;
        html += `<div class="p-topic-answer">`;
        html += `<div class="p-topic-answer-content" id="${answerid}">${answerContent}</div>`;
        html += `</div>`; //end topic-answer

        html += `<div class="p-topic-answer-footer" id="${footerId}">`;

        if (footerContent && footerContent !== '') {
          html += `<div>${footerContent}</div>`;
        } else {
          html += `<div></div>`;
        }

        html += `<div class="p-timestamp"><span>${response.formattedDate}</span><span class="p-indicator"></span></div>`;
        html += `</div>`; //end footer

        html += `</div>`; //end topic-answer-container

        html += `</div>`; //end response
      });
      return {
        html,
        footerActions
      };
    });

    _defineProperty(this, "templateGallery", (id, data) => {
      var galleryId = 'gallery-' + id;
      var html = `<div class="p-chat-gallery" id="${galleryId}">`;
      data.forEach(item => {
        let url = item.link;

        if (!url) {
          url = item.url;
        }

        let imageLink = item.imageLink;
        let title = item.title;

        if (!title) {
          title = item.description;
        }

        let imgId = 'img-' + this.randomId(6);
        let containerId = 'gallery-item-contains-' + imgId;
        html += `
            <div class="p-gallery-item-container" id="${containerId}">
                <a class="p-item" href="${url}" target="_blank">
                   <div class="p-image">
                     <div>
                       <img src="${imageLink}?width=320" onerror="onChatImageError(this)" data-id="${imgId}">
                     </div>   
                   </div>
                   <div class="p-link">
                      ${title}
                   </div>
                </a>
            </div>
        `;
      });
      html += '</div>';
      return html;
    });

    _defineProperty(this, "templateArray", (id, data) => {
      var arrayId = 'array-' + id;
      var html = `<div class="p-chat-array" id="${arrayId}">`;
      data.forEach(item => {
        html += `
            <a class="p-link" href="${item.link}" target="_blank">
              ${item.title}
            </a>
        `;
      });
      html += '</div>';
      return html;
    });

    _defineProperty(this, "templateSkeleton", () => {
      var html = `<div class="p-chat-container" id="p-chat-container">
                     <div class="p-chat-main" id="p-chat-main">
                       
                       <div class="p-main-content-container p-hide" id="p-main-content-container">
                           <div class="p-topic-responses-container" id="p-topic-responses-container"></div>
                           <div class="p-chat-generating-container">
                                 <div class="p-chat-generating" id="p-chat-generating">
                                    <svg stroke="currentColor" stroke-width="2" fill="none" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" class="flex-shrink-0 icon-xs" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><polyline points="1 4 1 10 7 10"></polyline><polyline points="23 20 23 14 17 14"></polyline><path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"></path></svg>
                                    <span id="p-chat-generating-text">Generating...</span>
                                 </div>
                           </div>
                       </div>
                       <div class="p-chat-loading-container" id="p-chat-loading-container">
                             <div class="p-loading-message">Loading Chat</div>
                             <div class="p-loading-animation">
                                <div class="p-chat-loader"></div>
                             </div>
                       </div>
                        <div class="p-chat-footer p-hide" id="p-chat-footer">
                             <div class="p-chat-footer-inner-container">
                                   <div class="p-chat-new-topic-button" id="p-chat-new-topic-button">
                                      <div>
                                         <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" height="24" width="24" fill="#ffffff">
                                           <path d="M12 2C17.5228 2 22 6.47715 22 12C22 12.2628 21.9899 12.5232 21.97 12.7809C21.5319 12.3658 21.0361 12.0111 20.4958 11.73C20.3532 7.16054 16.6041 3.5 12 3.5C7.30558 3.5 3.5 7.30558 3.5 12C3.5 13.4696 3.87277 14.8834 4.57303 16.1375L4.72368 16.4072L3.61096 20.3914L7.59755 19.2792L7.86709 19.4295C9.04305 20.0852 10.3592 20.4531 11.73 20.4958C12.0111 21.0361 12.3658 21.5319 12.7809 21.97C12.5232 21.9899 12.2628 22 12 22C10.3817 22 8.81782 21.6146 7.41286 20.888L3.58704 21.9553C2.92212 22.141 2.23258 21.7525 2.04691 21.0876C1.98546 20.8676 1.98549 20.6349 2.04695 20.4151L3.11461 16.5922C2.38637 15.186 2 13.6203 2 12C2 6.47715 6.47715 2 12 2ZM23 17.5C23 14.4624 20.5376 12 17.5 12C14.4624 12 12 14.4624 12 17.5C12 20.5376 14.4624 23 17.5 23C20.5376 23 23 20.5376 23 17.5ZM18.0006 18L18.0011 20.5035C18.0011 20.7797 17.7773 21.0035 17.5011 21.0035C17.225 21.0035 17.0011 20.7797 17.0011 20.5035L17.0006 18H14.4956C14.2197 18 13.9961 17.7762 13.9961 17.5C13.9961 17.2239 14.2197 17 14.4956 17H17.0005L17 14.4993C17 14.2231 17.2239 13.9993 17.5 13.9993C17.7761 13.9993 18 14.2231 18 14.4993L18.0005 17H20.4966C20.7725 17 20.9961 17.2239 20.9961 17.5C20.9961 17.7762 20.7725 18 20.4966 18H18.0006Z"></path>
                                         </svg>
                                      </div>
                                      <span class="p-tooltiptext">New Chat</span>
                                      <span class="p-hidden p-new-topic-text">New&nbsp;topic&nbsp;</span>
                                   </div>
                                   <div class="p-chat-textarea-container">
                                         <textarea id="p-chat-textarea" class="p-chat-textarea" placeholder="${this.CHAT_WELCOME_MESSAGE}"></textarea>
                                         <div class="p-textarea-icons">
                                              <div id="p-chat-upload-icon" title="Upload image" class="p-upload-image p-pointer">
                                                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" height="20" width="20">
                                                      <path d="M17.75 3C19.5449 3 21 4.45508 21 6.25V9.25C21 9.66421 20.6642 10 20.25 10C19.8358 10 19.5 9.66421 19.5 9.25V6.25C19.5 5.2835 18.7165 4.5 17.75 4.5L14.75 4.5C14.3358 4.5 14 4.16421 14 3.75C14 3.33579 14.3358 3 14.75 3H17.75ZM6.25 3C4.45507 3 3 4.45507 3 6.25V9.25C3 9.66421 3.33579 10 3.75 10C4.16421 10 4.5 9.66421 4.5 9.25V6.25C4.5 5.2835 5.2835 4.5 6.25 4.5H9.25C9.66421 4.5 10 4.16421 10 3.75C10 3.33579 9.66421 3 9.25 3H6.25ZM17.75 21C19.5449 21 21 19.5449 21 17.75V14.75C21 14.3358 20.6642 14 20.25 14C19.8358 14 19.5 14.3358 19.5 14.75V17.75C19.5 18.7165 18.7165 19.5 17.75 19.5H14.75C14.3358 19.5 14 19.8358 14 20.25C14 20.6642 14.3358 21 14.75 21H17.75ZM3 17.75C3 19.5449 4.45507 21 6.25 21H9.25C9.66421 21 10 20.6642 10 20.25C10 19.8358 9.66421 19.5 9.25 19.5H6.25C5.2835 19.5 4.5 18.7165 4.5 17.75L4.5 14.75C4.5 14.3358 4.16421 14 3.75 14C3.33579 14 3 14.3358 3 14.75L3 17.75ZM12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15ZM12 13.5C11.1716 13.5 10.5 12.8284 10.5 12C10.5 11.1716 11.1716 10.5 12 10.5C12.8284 10.5 13.5 11.1716 13.5 12C13.5 12.8284 12.8284 13.5 12 13.5ZM7.5 8.5C8.05229 8.5 8.5 8.05229 8.5 7.5C8.5 6.94772 8.05229 6.5 7.5 6.5C6.94772 6.5 6.5 6.94772 6.5 7.5C6.5 8.05229 6.94772 8.5 7.5 8.5Z"></path>
                                                    </svg>
                                               </div>
                                               <div id="p-chat-submit">
                                                     <svg class="p-chat-submit-icon" id="p-chat-submit-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" height="20" width="20">
                                                       <path d="M5.69362 11.9997L2.29933 3.2715C2.0631 2.66403 2.65544 2.08309 3.2414 2.28959L3.33375 2.32885L21.3337 11.3288C21.852 11.588 21.8844 12.2975 21.4309 12.6129L21.3337 12.6705L3.33375 21.6705C2.75077 21.962 2.11746 21.426 2.2688 20.8234L2.29933 20.7278L5.69362 11.9997L2.29933 3.2715L5.69362 11.9997ZM4.4021 4.54007L7.01109 11.2491L13.6387 11.2497C14.0184 11.2497 14.3322 11.5318 14.3818 11.8979L14.3887 11.9997C14.3887 12.3794 14.1065 12.6932 13.7404 12.7428L13.6387 12.7497L7.01109 12.7491L4.4021 19.4593L19.3213 11.9997L4.4021 4.54007Z"></path>
                                                    </svg>
                                               </div>
                                         </div>
                                   </div>

                             </div>
                        </div>

                     </div>
                   </div>`;
      return html;
    });

    _defineProperty(this, "parseArray", strArr => {
      let formattedString = strArr.replace(/'/g, '"'); // Step 2: Extract the invalid element's text

      const invalidElementRegex = /,\s*\.\.\.\s*\(plus more listings\)\s*\]/;
      const invalidElementMatch = formattedString.match(invalidElementRegex);
      const label = invalidElementMatch ? invalidElementMatch[0].replace(/[,\[\]]/g, '').trim() : null; // Step 3: Remove invalid elements from the string

      formattedString = formattedString.replace(invalidElementRegex, ']'); // Step 4: Parse the corrected string and create the result object

      formattedString = formattedString.replace(/\\\"/g, '"');
      formattedString = formattedString.replace(/\\n/g, '');
      let resultObject = {
        value: null,
        text: label
      };

      if (label) {
        resultObject.label = label.replace(/[^a-zA-Z\s]/g, '').trim();
      }

      try {
        resultObject.value = JSON.parse(formattedString);
      } catch (e) {
        console.error("Error parsing JSON: ", e);
      }

      return resultObject;
    });

    _defineProperty(this, "trimNonAlphanumeric", str => {
      //return str.replace(/^[^a-zA-Z0-9]+/, '').replace(/[^a-zA-Z0-9]+$/, '');
      return str.replace(/^[^a-zA-Z0-9]+/, '');
    });

    _defineProperty(this, "formatAnswerFromResponse", str => {
      let regex = /\[([^\]]+)\]\(([^)]+)\)|\{([^}]+)\}|\[([^\]]+)\]|([^[\{]+)/g;
      let parts = [];
      let match;

      while ((match = regex.exec(str)) !== null) {
        if (match[1]) {
          // Bracket type
          parts.push({
            type: 'BRACKET',
            value: {
              text: match[1],
              link: match[2]
            }
          });
        } else if (match[3]) {
          // Object type
          try {
            let strObj = "{" + match[3] + "}";
            strObj = JSON.stringify(strObj);
            let obj = JSON.parse(strObj);
            let regex2 = /('(?=(,\s*')))|('(?=:))|((?<=([:,]\s*))')|((?<={)')|('(?=}))/g;
            obj = obj.replace(regex2, '"');
            obj = JSON.parse(obj);
            parts.push({
              type: 'OBJECT',
              value: obj
            });
          } catch (e) {
            console.error('Invalid object syntax');
          }
        } else if (match[4]) {
          // Array type
          try {
            let array = [];
            let label = null;
            let text = null;
            let action = null;
            let arr = match[4];
            let firstElement = {};
            let isImageGrid = false;

            if (Array.isArray(arr)) {
              firstElement = arr[0];
              if (firstElement.imageLink) isImageGrid = true;
              parts.push({
                type: 'ARRAY',
                value: arr,
                isImageGrid: isImageGrid
              });
            } else if (typeof arr === 'string') {
              arr = arr.trim();

              if (arr.indexOf('[') !== 0) {
                arr = "[" + arr + "]";
              }

              arr = JSON.stringify(arr);
              arr = JSON.parse(arr);

              if (typeof arr === 'string') {
                let parsedArr = parseArray(arr);

                if (parsedArr) {
                  array = parsedArr.value;
                  text = parsedArr.text;
                  label = parsedArr.label;

                  if (label === 'plus more listings') {
                    action = 'See more listings';
                  }
                }
              } else {
                array = arr;
              }

              firstElement = array[0];
              if (firstElement.imageLink) isImageGrid = true;
              parts.push({
                type: 'ARRAY',
                value: array,
                label: label,
                text: text,
                action: action,
                isImageGrid: isImageGrid
              });
            }
          } catch (e) {
            console.error('Invalid array syntax');
          }
        } else if (match[5]) {
          // Text type
          let text = match[5].trim();
          text = text.replace(/\n/g, '');
          text = text.replace(/\\n/g, '');
          text = text.replace(/n\n/g, '');
          text = text.replace(/n\\n/g, '');
          text = this.trimNonAlphanumeric(text);
          console.log(text);

          if (text) {
            parts.push({
              type: 'TEXT',
              value: text,
              label: null
            });
          }
        }
      }

      return parts;
    });

    _defineProperty(this, "parseApiResponse", obj => {
      var response = obj.response;
      var isJsonResponse = response.indexOf('```json') > -1;

      if (isJsonResponse) {
        let apiResponse = response;
        let jsonString = apiResponse.split("```json\n")[1].split("\n```")[0]; // Parse the JSON string into an object
        //console.log(jsonString);

        jsonString = jsonString.replace(/[\u0000-\u001F\u007F-\u009F\u061C\u200E\u200F\u202A-\u202E\u2066-\u2069]/g, "");
        jsonString = JSON.stringify(jsonString);
        let responseObject = JSON.parse(jsonString);

        if (!responseObject.response) {
          responseObject = JSON.parse(responseObject);
        }

        responseObject.response = JSON.stringify(responseObject.response); // Extract the additional string content from the API response

        let additionalString = apiResponse.split("\n```")[1]; // Append the additional string content to the response

        responseObject.response += additionalString;
        return responseObject;
      } else {
        return obj;
      }
    });

    _defineProperty(this, "formatDefaultAnswer", value => {
      return [{
        label: null,
        type: 'TEXT',
        value: value
      }];
    });

    _defineProperty(this, "_tryReformattingArray", formattedString => {
      formattedString = formattedString.replace(/\+/g, '');
      formattedString = formattedString.replace(/\\n"/g, '');
      formattedString = formattedString.replace(/\"\\"/g, "\\\"");
      formattedString = formattedString.replace(/\"{/g, '{');
      formattedString = formattedString.replace(/\"}/g, '}');
      formattedString = formattedString.replace(/\\\"}/g, '\"');
      formattedString = formattedString.replace(/\\/g, '');
      return formattedString;
    });

    _defineProperty(this, "_tryReformattingObject", formattedString => {
      formattedString = formattedString.replace(/\\/g, '');
      return formattedString;
    });

    _defineProperty(this, "parseArrayV2", strArr => {
      let error = false;
      let formattedString = strArr;
      let resultObject = {
        value: null,
        text: null
      };

      try {
        resultObject.value = JSON.parse(formattedString);
      } catch (e) {
        error = true;
        console.error("First Attempt Error parsing JSON: ", e);
      }

      if (error) {
        formattedString = this._tryReformattingArray(formattedString);

        try {
          resultObject.value = JSON.parse(formattedString);
        } catch (ex) {
          console.error("Second Attempt Error parsing JSON: ", e);
        }
      }

      return resultObject;
    });

    _defineProperty(this, "parseApiResponseV2", obj => {
      let isJsonResponse = obj.response.indexOf('```json') > -1;
      if (isJsonResponse) return this.parseApiResponse(obj);
      let error = false;
      let parsedResponse = null;
      let type = null;
      let objResponse = obj.response;
      if (!objResponse) objResponse = obj;
      let response = null;
      let stringParsed = null;
      let v2 = true;

      try {
        parsedResponse = JSON.parse(objResponse);
      } catch (ex) {
        error = true;
      }

      if (error) {
        stringParsed = this._parseReponseAndTypeFromApiResult(objResponse);
      }

      if (parsedResponse && parsedResponse.response) {
        response = parsedResponse.response;
        if (parsedResponse.type) type = parsedResponse.type;
      } else if (parsedResponse) {
        response = parsedResponse;
      } else if (stringParsed) {
        response = stringParsed.response;
        if (stringParsed.type) type = stringParsed.type;
      }

      return {
        response,
        type,
        v2
      };
    });

    _defineProperty(this, "formatAnswerFromResponseV2", str => {
      str = str.replace(/[\u0000-\u001F\u007F-\u009F\u061C\u200E\u200F\u202A-\u202E\u2066-\u2069]/g, "");
      let regex = /\[([^\]]+)\]\(([^)]+)\)|\{([^}]+)\}|\[([^\]]+)\]|([^[\{]+)/g;
      let parts = [];
      let match;

      while ((match = regex.exec(str)) !== null) {
        if (match[1]) {
          // Bracket type
          parts.push({
            type: 'BRACKET',
            value: {
              text: match[1],
              link: match[2]
            }
          });
        } else if (match[3]) {
          // Object type
          let strObj = null;
          let objError = false;
          let regex2 = /('(?=(,\s*')))|('(?=:))|((?<=([:,]\s*))')|((?<={)')|('(?=}))/g;

          try {
            strObj = "{" + match[3] + "}";
            strObj = JSON.stringify(strObj);
            let obj = JSON.parse(strObj);
            obj = obj.replace(regex2, '"');
            obj = JSON.parse(obj);
            parts.push({
              type: 'OBJECT',
              value: obj
            });
          } catch (e) {
            console.error('First Attempt: Invalid object syntax');
            objError = true;
          }

          if (objError) {
            try {
              let obj = JSON.parse(strObj);
              obj = obj.replace(regex2, '"');
              obj = this._tryReformattingObject(obj);
              obj = JSON.parse(obj);
              parts.push({
                type: 'OBJECT',
                value: obj
              });
            } catch (ex) {
              console.error('Second Attempt: Invalid object syntax');
            }
          }
        } else if (match[4]) {
          // Array type
          try {
            let array = [];
            let label = null;
            let text = null;
            let action = null;
            let arr = match[4];
            let firstElement = {};
            let isImageGrid = false;

            if (Array.isArray(arr)) {
              firstElement = arr[0];
              if (firstElement.imageLink) isImageGrid = true;
              parts.push({
                type: 'ARRAY',
                value: arr,
                isImageGrid: isImageGrid
              });
            } else if (typeof arr === 'string') {
              arr = arr.trim();

              if (arr.indexOf('[') !== 0) {
                arr = "[" + arr + "]";
              }

              arr = JSON.stringify(arr);
              arr = JSON.parse(arr);

              if (typeof arr === 'string') {
                let parsedArr = this.parseArrayV2(arr);

                if (parsedArr) {
                  array = parsedArr.value;
                  text = parsedArr.text;
                  label = parsedArr.label;

                  if (label === 'plus more listings') {
                    action = 'See more listings';
                  }
                }
              } else {
                array = arr;
              }

              firstElement = array[0];
              if (firstElement.imageLink) isImageGrid = true;
              parts.push({
                type: 'ARRAY',
                value: array,
                label: label,
                text: text,
                action: action,
                isImageGrid: isImageGrid
              });
            }
          } catch (e) {
            console.error('Invalid array syntax');
          }
        } else if (match[5]) {
          // Text type
          let text = match[5].trim();
          text = text.replace(/\n/g, '');
          text = text.replace(/\\n/g, '');
          text = text.replace(/n\n/g, '');
          text = text.replace(/n\\n/g, '');
          text = this.trimNonAlphanumeric(text);

          if (text) {
            parts.push({
              type: 'TEXT',
              value: text,
              label: null
            });
          }
        }
      }

      return parts;
    });

    _defineProperty(this, "_parseReponseAndTypeFromApiResult", response => {
      try {
        let resp = response.split('"response":');
        let jsonString = resp[1].replace(/[\u0000-\u001F\u007F-\u009F\u061C\u200E\u200F\u202A-\u202E\u2066-\u2069]/g, "");
        jsonString = jsonString.substring(1, jsonString.length - 1);
        const typeRegex = /"type":\s*"([^"]*)"/g;
        const match = typeRegex.exec(response);
        let type = null;
        if (match) type = match[1];
        return {
          response: jsonString,
          type: type
        };
      } catch (ex) {
        return null;
      }
    });

    _defineProperty(this, "forEachPromise", (items, element, fn) => {
      return items.reduce(function (promise, item) {
        return promise.then(function () {
          return fn(item, element);
        });
      }, Promise.resolve());
    });

    _defineProperty(this, "randomId", length => {
      var chars = "0123456789";
      var result = "";

      for (var i = length; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];

      return result;
    });

    _defineProperty(this, "guid", () => {
      var S4 = function S4() {
        return ((1 + Math.random()) * 65536 | 0).toString(16).substring(1);
      };

      return S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4();
    });

    _defineProperty(this, "formattedDate", time => {
      // Get the current timestamp (milliseconds since January 1, 1970)
      const currentTimeStamp = Date.now();

      if (time) {
        currentTimeStamp = time;
      } // Create a Date object from the timestamp


      const currentDate = new Date(currentTimeStamp); // Define the months and days arrays for formatting

      const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']; // Format the date and time

      const formattedDate = `${months[currentDate.getMonth()]} ${currentDate.getDate()}, ${currentDate.getFullYear()}, ${currentDate.toLocaleTimeString()}`;
      return formattedDate;
    });

    _defineProperty(this, "arrayFindByIdentifier", (arr, id, value) => {
      var length = arr.length;
      var result = null;

      for (var i = 0; i < length; i++) {
        if (arr[i][id] === value) {
          result = arr[i];
          break;
        }
      }

      return result;
    });
  }

  // #region "Getters/Setters"
  get X_ASSISTANT_ID() {
    return this.__X_ASSISTANT_ID;
  }

  set X_ASSISTANT_ID(val) {
    this.__X_ASSISTANT_ID = val;
  }

  get CHAT_CACHE_KEY() {
    return this.__CHAT_CACHE_KEY;
  }

  set CHAT_CACHE_KEY(val) {
    this.__CHAT_CACHE_KEY = val;
  }

  get CHAT_SESSION_ID() {
    return this.__CHAT_SESSION_ID;
  }

  set CHAT_SESSION_ID(val) {
    this.__CHAT_SESSION_ID = val;
  }

  get CHAT_RUN_ID() {
    return this.__CHAT_RUN_ID;
  }

  set CHAT_RUN_ID(val) {
    this.__CHAT_RUN_ID = val;
  }

  get CHAT_RUN_POLL_INTERVAL() {
    return 1000;
  }

  get USER_ID() {
    return this.__USER_ID;
  }

  set USER_ID(val) {
    this.__USER_ID = val;
  }

  get X_TENANT_ID() {
    return this.__X_TENANT_ID;
  }

  set X_TENANT_ID(val) {
    this.__X_TENANT_ID = val;
  }

  get API_ROOT() {
    return this.__API_ROOT;
  }

  set API_ROOT(val) {
    this.__API_ROOT = val;
  }

  get API_POST_CHAT() {
    return this.API_ROOT;
  }

  get CHAT_INTERFACE() {
    return this.__CHAT_INTERFACE;
  }

  set CHAT_INTERFACE(val) {
    this.__CHAT_INTERFACE = val;
  }

  get CHAT_IN_PROGRESS() {
    return this.__CHAT_IN_PROGRESS;
  }

  set CHAT_IN_PROGRESS(val) {
    this.__CHAT_IN_PROGRESS = val;
  }

  get TEST_RESPONSE_MODE() {
    return false;
  }

  get CHAT_CONTAINER() {
    return this.__CHAT_CONTAINER;
  }

  set CHAT_CONTAINER(val) {
    this.__CHAT_CONTAINER = val;
  }

  get CHAT_MAIN_CONTENT_CONTAINER() {
    return this.__CHAT_MAIN_CONTENT_CONTAINER;
  }

  set CHAT_MAIN_CONTENT_CONTAINER(val) {
    this.__CHAT_MAIN_CONTENT_CONTAINER = val;
  }

  get CHAT_TEXTAREA() {
    return this.__CHAT_TEXTAREA;
  }

  set CHAT_TEXTAREA(val) {
    this.__CHAT_TEXTAREA = val;
  }

  get CHAT_GENERATING() {
    return this.__CHAT_GENERATING;
  }

  set CHAT_GENERATING(val) {
    this.__CHAT_GENERATING = val;
  }

  get CHAT_GENERATING_TEXT() {
    return this.__CHAT_GENERATING_TEXT;
  }

  set CHAT_GENERATING_TEXT(val) {
    this.__CHAT_GENERATING_TEXT = val;
  }

  get CHAT_RESPONSES_CONTAINER() {
    return this.__CHAT_RESPONSES_CONTAINER;
  }

  set CHAT_RESPONSES_CONTAINER(val) {
    this.__CHAT_RESPONSES_CONTAINER = val;
  }

  get CHAT_SUBMIT_ICON() {
    return this.__CHAT_SUBMIT_ICON;
  }

  set CHAT_SUBMIT_ICON(val) {
    this.__CHAT_SUBMIT_ICON = val;
  }

  get CHAT_LOADER() {
    return this.__CHAT_LOADER;
  }

  set CHAT_LOADER(val) {
    this.__CHAT_LOADER = val;
  }

  get CHAT_FOOTER() {
    return this.__CHAT_FOOTER;
  }

  set CHAT_FOOTER(val) {
    this.__CHAT_FOOTER = val;
  }

  get CHAT_NEW_TOPIC_BUTTON() {
    return this.__CHAT_NEW_TOPIC_BUTTON;
  }

  set CHAT_NEW_TOPIC_BUTTON(val) {
    this.__CHAT_NEW_TOPIC_BUTTON = val;
  }

  get CHAT_WELCOME_MESSAGE() {
    return this.__CHAT_WELCOME_MESSAGE;
  }

  set CHAT_WELCOME_MESSAGE(val) {
    this.__CHAT_WELCOME_MESSAGE = val;
  }

  get MESSAGE_CHAT_CREATE_ERROR() {
    return "I apologize. I'm currently unavailable to start a new chat with you.";
  }

  get MESSAGE_CHAT_POST_ERROR() {
    return "Woops...I'm currently unable to answer your question. You can try to submit it again.";
  }

  get MESSAGE_CHAT_RUN_ERROR() {
    return "Hmmm...I'm taking too long to respond to your question. You can try to submit it again.";
  }

  get ACTION_APPLY_IMAGE_404_HANDLER() {
    return true;
  }

  get MESSAGE_CHAT_TIMEOUT_ERROR() {
    return this.__MESSAGE_CHAT_TIMEOUT_ERROR;
  }

  set MESSAGE_CHAT_TIMEOUT_ERROR(val) {
    this.__MESSAGE_CHAT_TIMEOUT_ERROR = val;
  } // #endregion
  // #region "RUN"
  // #endregion


}