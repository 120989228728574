class PaginationProvider {
  static get(params, data) {
    if (params.paginate) params = params.paginate; //page part of the url path or in a page query param, e.g, , /action/:page, or /action/:id?page=page

    var usePageQueryString = this.pageQueryString; //if applicable: override static pageQueryString prop w/ optional prop in the passed params object

    if (typeof params.usePageQueryString !== 'undefined') {
      usePageQueryString = params.usePageQueryString;
    }
    /** return paginated data object
     *    result.data {data array}
      *    result.pagination {object}
    */


    return this._pagination(params, data, usePageQueryString);
  }

  static _pagination(params, result, useQueryString) {
    var count_ = this.count;
    var data_ = this.data;
    var spread_ = this.spread;
    var baseUrl, rawUrl, page, count, pageSize, pageSpread, data;
    baseUrl = params.baseUrl;
    rawUrl = params.rawUrl;
    page = params.page;

    if (result instanceof Array) {
      count = result.length;
      data = result;
    } else {
      count = result[count_];
      data = result[data_];
      if (count === undefined) count = result.count;
      if (data === undefined) data = result.data;
    }

    if (!count) count = 0;
    pageSize = params.pageSize;
    pageSpread = spread_;

    try {
      page = parseInt(page);
    } catch (ex) {
      page = 1;
    }

    var pageCount = parseInt(count / pageSize);
    var remainder = count % pageSize;
    if (pageCount < 1) pageCount = 1;else if (remainder > 0) pageCount++; //query search part of url

    var querySearch = this._getQuerySearch(rawUrl); //pagination object


    var pagination = {
      page: page,
      pageCount: pageCount,
      prevPage: this._assignUrl(baseUrl, 1, querySearch, rawUrl, useQueryString),
      firstPage: null,
      prevClass: 'hide',
      nextPage: this._assignUrl(baseUrl, pageCount, querySearch, rawUrl, useQueryString),
      nextClass: 'hide',
      lastPage: null,
      pages: [],
      beginRecord: null,
      endRecord: null,
      count: count,
      nextPageNo: null,
      prevPageNo: null
    }; //assign pagination properties
    //prev

    if (page > 1) {
      pagination.prevClass = '';
      pagination.prevPage = this._assignUrl(baseUrl, parseInt(page - 1), querySearch, rawUrl, useQueryString);
      pagination.prevPageNo = page - 1;
    } //next


    if (page < pageCount) {
      pagination.nextClass = '';
      pagination.nextPage = this._assignUrl(baseUrl, parseInt(page + 1), querySearch, rawUrl, useQueryString);
      pagination.nextPageNo = page + 1;
    } //get page links


    pagination.pages = this._pageLinks(baseUrl, page, pageCount, pageSpread, querySearch, rawUrl, useQueryString); //first,last pages

    pagination.firstPage = this._assignUrl(baseUrl, 1, querySearch, rawUrl, useQueryString);
    pagination.lastPage = this._assignUrl(baseUrl, pageCount, querySearch, rawUrl, useQueryString);
    if (page === pageCount) pagination.nextPage = pagination.lastPage; //assign record pointers

    var currentPointer = this._assignRecordPointers(count, page, pageSize);

    pagination.beginRecord = currentPointer.beginRecord;
    pagination.endRecord = currentPointer.endRecord;
    /*return {
        pagination: pagination,
        data: data
    };*/

    var retObj = {
      pagination: pagination
    };
    retObj[this.data] = data;
    return retObj;
  }

  static _pageLinks(baseUrl, page, pageCount, pageSpread, querySearch, rawUrl, useQueryString) {
    var pages = [];

    if (pageSpread > pageCount) {
      pageSpread = pageCount;
    }

    if (page < pageSpread) {
      for (var i = 0; i < pageSpread; i++) {
        var obj = {
          page: i + 1,
          pageUrl: this._assignUrl(baseUrl, parseInt(i + 1), querySearch, rawUrl, useQueryString)
        };
        if (i === parseInt(page - 1)) obj.activePage = 'active';
        pages.push(obj);
      }

      return pages;
    } else {
      var halfSpread = parseInt(pageSpread / 2);
      var beginPage, endPage;

      if (pageCount < page + halfSpread) {
        endPage = pageCount;
        beginPage = endPage - pageSpread;
      } else {
        endPage = page + halfSpread;
        beginPage = page - halfSpread;
      }

      if (beginPage === 0) beginPage = 1;

      for (var i = beginPage; i < endPage + 1; i++) {
        var obj = {
          page: i,
          pageUrl: this._assignUrl(baseUrl, i, querySearch, rawUrl, useQueryString)
        };
        if (i === page) obj.activePage = 'active';
        pages.push(obj);
      }

      return pages;
    }
  }

  static _assignUrl(baseUrl, index, querySearch, rawUrl, useQueryString) {
    var pageUrl;

    if (useQueryString) {
      pageUrl = baseUrl + this._setQuery('page', index, querySearch, rawUrl);
    } else {
      pageUrl = baseUrl + '/' + index;
      if (querySearch && querySearch !== undefined) pageUrl += querySearch;
    }

    return pageUrl;
  }

  static _assignRecordPointers(count, page, pageSize) {
    var beginRecord = (page - 1) * pageSize + 1;
    if (count === 0) beginRecord = 0;
    var endRecord = page * pageSize;
    if (endRecord > count) endRecord = count;
    return {
      beginRecord: beginRecord,
      endRecord: endRecord
    };
  }

  static _getQuerySearch(url) {
    if (!url) return null;
    var index = url.indexOf('?');
    var length = url.length;
    if (index > -1) return url.substring(index, length);else return null;
  }

  static _queryString(url, ji) {
    var hu = url.split('?')[1];

    if (typeof hu !== 'undefined') {
      var gy = hu.split("&");

      for (var i = 0; i < gy.length; i++) {
        var ft = gy[i].split("=");
        if (ft[0] == ji) return ft[1];
      }
    }

    return null;
  }

  static _setQuery(key, val, search, u) {
    if (search && search !== '') {
      var val_ = this._queryString(u, key);

      if (!val_) search += '&' + key + '=' + encodeURIComponent(val);else search = search.replace(key + '=' + val_, key + '=' + val);
    } else search = '?' + key + '=' + encodeURIComponent(val);

    return search;
  }

  get(params, data) {
    return this.constructor.get(params, data);
  }

  _pagination(params, result, useQueryString) {
    return this.constructor._pagination(params, result, useQueryString);
  }

  _pageLinks(baseUrl, page, pageCount, pageSpread, querySearch, rawUrl, useQueryString) {
    return this.constructor._pageLinks(baseUrl, page, pageCount, pageSpread, querySearch, rawUrl, useQueryString);
  }

  _assignUrl(baseUrl, index, querySearch, rawUrl, useQueryString) {
    return this.constructor._assignUrl(baseUrl, index, querySearch, rawUrl, useQueryString);
  }

  _assignRecordPointers(count, page, pageSize) {
    return this.constructor._assignRecordPointers(count, page, pageSize);
  }

  _getQuerySearch(url) {
    return this.constructor._getQuerySearch(url);
  }

  _queryString(url, ji) {
    return this.constructor._queryString(url, ji);
  }

  _setQuery(key, val, search, u) {
    return this.constructor._setQuery(key, val, search, u);
  }

}

PaginationProvider.count = 'count';
PaginationProvider.data = 'items';
PaginationProvider.spread = 10;
PaginationProvider.pageQueryString = true;
export default PaginationProvider;