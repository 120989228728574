export class AuthBase {
  static get authEndpoint() {
    if (this._authEndpoint) return this._authEndpoint;else return 'https://w0thor19ea.execute-api.us-east-1.amazonaws.com/Prod/api';
  }

  static set authEndpoint(val) {
    this._authEndpoint = val;
  }

  static get baseEndpoint() {
    return this._baseEndpoint;
  }

  static set baseEndpoint(val) {
    this._baseEndpoint = val;
  }

  static get rootEndpoint() {
    return this._rootEndpoint;
  }

  static set rootEndpoint(val) {
    this._rootEndpoint = val;
  }

  static get rootQuery() {
    return this._rootQuery;
  }

  static set rootQuery(val) {
    this._rootQuery = val;
  }

  static set refreshTokenApi(val) {
    this._refreshTokenApi = val;
  }

  static get refreshTokenApi() {
    if (this._refreshTokenApi) return this._refreshTokenApi;else return this.authEndpoint + '/RefreshToken';
  }

  static get headers() {
    return this._headers;
  }

  static set headers(val) {
    this._headers = val;
  }

  static get refreshToken() {
    var tokens = this.tokens;
    return tokens.refreshToken;
  }

  static get accessToken() {
    var tokens = this.tokens;
    return tokens.accessToken;
  }

  static get hasAccessToken() {
    var tokens = this.tokens;
    return tokens && tokens.accessToken;
  }

  static get apiKey() {
    return localStorage.getItem('plunkApiKeyToken');
  }

  static set apiKey(val) {
    localStorage.setItem('plunkApiKeyToken', val);
  }

  static get account() {
    return localStorage.getItem('plunkAccountToken');
  }

  static set account(val) {
    localStorage.setItem('plunkAccountToken', val);
  }

  static get tokens() {
    var value = localStorage.getItem('plunkOAuthTokens');
    if (!value) return {};

    try {
      value = JSON.parse(value);
    } catch (ex) {
      value = {};
    }

    return value;
  }

  static set tokens(value) {
    var tokens = {
      accessToken: value.accessToken,
      idToken: value.idToken
    };
    tokens.refreshToken = value.refreshToken ? value.refreshToken : this.refreshToken;
    var json = JSON.stringify(tokens);
    localStorage.setItem('plunkOAuthTokens', json);
  }

  static set newTokens(value) {
    var json = JSON.stringify(value);
    localStorage.setItem('plunkOAuthTokens', json);
  }

  static get CHALLENGE() {
    return {
      SMS_MFA: 'SMS_MFA',
      SOFTWARE_TOKEN_MFA: 'SOFTWARE_TOKEN_MFA',
      SELECT_MFA_TYPE: 'SELECT_MFA_TYPE',
      MFA_SETUP: 'MFA_SETUP',
      PASSWORD_VERIFIER: 'PASSWORD_VERIFIER',
      CUSTOM_CHALLENGE: 'CUSTOM_CHALLENGE',
      DEVICE_SRP_AUTH: 'DEVICE_SRP_AUTH',
      DEVICE_PASSWORD_VERIFIER: 'DEVICE_PASSWORD_VERIFIER',
      ADMIN_NO_SRP_AUTH: 'ADMIN_NO_SRP_AUTH',
      NEW_PASSWORD_REQUIRED: 'NEW_PASSWORD_REQUIRED'
    };
  }

  static getEndpoint(endpoint) {
    let url = endpoint;
    if (!url && this.endpoint) url = this.endpoint;
    let baseUrl = this.baseEndpoint;
    if (url && this.lastChar(url) === '/') url = this.trimLastChar(url);

    if (baseUrl && url) {
      if (this.lastChar(baseUrl) === '/' && this.firstChar(url) === '/') {
        url = this.trimFirstChar(url);
      } else if (this.lastChar(baseUrl) !== '/' && this.firstChar(url) !== '/') {
        url = '/' + url;
      }

      url = baseUrl + url;
    }

    if (!url && this.rootEndpoint) {
      url = this.rootEndpoint;
      if (url && this.lastChar(url) === '/') url = this.trimLastChar(url);
    }

    return url;
  }

  static removeTokens() {
    localStorage.removeItem('plunkOAuthTokens');
  }

  static removeApiKeyToken() {
    localStorage.removeItem('plunkApiKeyToken');
  }

  static removeAccountToken() {
    localStorage.removeItem('plunkAccountToken');
  }

  static removeAllTokens() {
    this.removeTokens();
    this.removeApiKeyToken;
    this.removeAccountToken;
  }

  static async isAuthenticatedAsync() {
    return new Promise((resolve, reject) => {
      this.fetchAuthStatus(data => {
        resolve(data);
      });
    });
  }

  static refreshTokens(callback) {
    this.getFreshTokens(this.refreshToken, (err, data) => {
      callback(err, data);
    });
  }

  static async refreshTokensAsync() {
    return new Promise((resolve, reject) => {
      this.getFreshTokens(this.refreshToken, (err, data) => {
        if (err) reject(err);else resolve(data);
      });
    });
  }

  static isAuthenticated(callback) {
    this.fetchAuthStatus(data => {
      callback(data);
    });
  }

  static getParamsString(params) {
    let q = '';

    for (var key in params) {
      if (params.hasOwnProperty(key)) {
        var val = params[key];
        q += '/' + val;
      }
    }

    return q;
  }

  static getFetchObj(method, value, endpoint) {
    let url = this.getEndpoint(endpoint);
    let obj = {
      url: url,
      options: {
        method: method
      }
    };

    if (method === 'get' && value) {
      url += value;
      obj.url = url;
    } else if (method === 'delete' && value) {
      url += '/' + value;
      obj.url = url;
    } else if (method === 'post' || method === 'put') {
      if (typeof value !== 'string') value = JSON.stringify(value);
      obj.options.body = value;
    }

    obj.options.mode = 'cors';
    obj.options.headers = this.getHeaders();
    return obj;
  }

  static getHeaders() {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    var accessToken = this.accessToken;
    var apiKey = this.apiKey;
    var account = this.account;

    if (accessToken) {
      headers.append('Authorization', 'Bearer ' + accessToken);
    }

    if (apiKey) {
      headers.append('X-API-KEY', apiKey);
    }

    if (account) {
      headers.append('X-ACCOUNT-ID', account);
    }

    let values = this.headers;

    if (values && values.length) {
      values.forEach(obj => {
        let s = obj.split(':');
        headers.append(s[0], s[1]);
      });
    }

    return headers;
  }

  static getFreshTokens(refreshToken, callback) {
    let Ok = false;
    let status = null;
    let statusText = null;
    let errObj = {
      message: null,
      statusCode: null
    };
    var url = this.refreshTokenApi;
    var tokenModel = {
      refreshToken: refreshToken
    };
    var json = JSON.stringify(tokenModel);
    var options = {
      method: 'post',
      body: json
    };
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    options.headers = headers;
    options.mode = 'cors';
    fetch(url, options).then(response => {
      Ok = response.ok;
      status = response.status;
      statusText = response.statusText;
      return response;
    }).then(response => response.text()).then(text => {
      if (Ok) {
        try {
          let data = JSON.parse(text);
          this.tokens = data;
          callback(null, data);
        } catch (ex) {
          errObj = {
            statusCode: 500,
            message: 'Error parsing access tokens'
          };
          if (this.onAuthTokenFailure) this.onAuthTokenFailure(errObj, callback);else callback(errObj, null);
        }
      } else {
        errObj.statusCode = status;

        try {
          let data = JSON.parse(text);
          if (data && data.message) errObj.message = data.message;else errObj.message = statusText;
          if (this.onAuthTokenFailure) this.onAuthTokenFailure(errObj, callback);else callback(errObj, null);
        } catch (ex) {
          errObj = {
            statusCode: 500,
            message: 'Error parsing access tokens'
          };
          if (this.onAuthTokenFailure) this.onAuthTokenFailure(errObj, callback);else callback(errObj, null);
        }
      }
    }).catch(err => {
      console.log(err);
      if (typeof err === 'string') statusText = err;
      errObj.statusCode = 500;
      errObj.message = statusText;
      if (this.onAuthTokenFailure) this.onAuthTokenFailure(errObj, callback);else callback(errObj, null);
    });
  }

  static fetchAuthStatus(callback) {
    let Ok = false;
    let url = this.authEndpoint + '/User/Authenticated';
    let options = {
      method: 'get'
    };
    options.headers = this.getHeaders();
    options.mode = 'cors';
    fetch(url, options).then(response => {
      Ok = response.ok;
      return response;
    }).then(response => response.text()).then(text => {
      if (Ok) {
        let data = JSON.parse(text);
        callback(data.isAuthenticated);
      } else {
        callback(false);
      }
    }).catch(err => {
      callback(false);
    });
  }

  static lastChar(s) {
    return s.slice(-1);
  }

  static firstChar(s) {
    return s.charAt(0);
  }

  static trimLastChar(s) {
    return s.substring(0, s.length - 1);
  }

  static trimFirstChar(s) {
    return s.substr(1);
  }

  get authEndpoint() {
    return this.constructor.authEndpoint;
  }

  set authEndpoint(val) {
    this.constructor.authEndpoint = val;
  }

  get baseEndpoint() {
    return this.constructor.baseEndpoint;
  }

  set baseEndpoint(val) {
    this.constructor.baseEndpoint = val;
  }

  get refreshTokenApi() {
    return this.constructor.refreshTokenApi;
  }

  set refreshTokenApi(val) {
    this.constructor.refreshTokenApi = val;
  }

  get headers() {
    var headers = [];
    var constructorHeaders = this.constructor._headers;
    var instanceHeaders = this._headers;
    if (Array.isArray(constructorHeaders)) headers = headers.concat(constructorHeaders);
    if (Array.isArray(instanceHeaders)) headers = headers.concat(instanceHeaders);
    return headers;
  }

  set headers(val) {
    this._headers = val;
  }

  get refreshToken() {
    return this.constructor.refreshToken;
  }

  get accessToken() {
    return this.constructor.accessToken;
  }

  get hasAccessToken() {
    return this.constructor.hasAccessToken;
  }

  get apiKey() {
    return this.constructor.apiKey;
  }

  set apiKey(val) {
    this.constructor.apiKey = val;
  }

  get account() {
    return this.constructor.account;
  }

  set account(val) {
    this.constructor.account = val;
  }

  get tokens() {
    return this.constructor.tokens;
  }

  set tokens(value) {
    this.constructor.tokens = value;
  }

  set newTokens(value) {
    this.constructor.newTokens = value;
  }

  get CHALLENGE() {
    return this.constructor.CHALLENGE;
  }

  getEndpoint(endpoint) {
    return this.constructor.getEndpoint(endpoint);
  }

  removeTokens() {
    this.constructor.removeTokens();
  }

  removeApiKeyToken() {
    this.constructor.removeApiKeyToken();
  }

  removeAccountToken() {
    this.constructor.removeAccountToken;
  }

  removeAllTokens() {
    this.constructor.removeAllTokens();
  }

  async isAuthenticatedAsync() {
    return new Promise((resolve, reject) => {
      this.fetchAuthStatus(data => {
        resolve(data);
      });
    });
  }

  isAuthenticated(callback) {
    return this.isAuthenticated(callback);
  }

  refreshTokens(callback) {
    return this.refreshTokens(callback);
  }

  async refreshTokensAsync() {
    return new Promise((resolve, reject) => {
      this.getFreshTokens(this.refreshToken, (err, data) => {
        if (err) reject(err);else resolve(data);
      });
    });
  }

  getParamsString(params) {
    return this.constructor.getParamsString(params);
  }

  getFetchObj(method, value, endpoint) {
    return this.constructor.getFetchObj(method, value, endpoint);
  }

  getHeaders() {
    return this.constructor.getHeaders();
  }

  getFreshTokens(refreshToken, callback) {
    return this.constructor.getFreshTokens(refreshToken, callback);
  }

  fetchAuthStatus(callback) {
    return this.constructor.fetchAuthStatus(callback);
  }

  lastChar(s) {
    return this.constructor.lastChar(s);
  }

  firstChar(s) {
    return this.constructor.firstChar(s);
  }

  trimLastChar(s) {
    return this.constructor.trimLastChar(s);
  }

  trimFirstChar(s) {
    return this.constructor.trimFirstChar(s);
  }

}