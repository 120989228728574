export const groupByLabel = value => {
  switch (value) {
    case 'Source':
      return 'Is Source';

    case 'Value':
      return 'Has Value';

    case 'Campaign':
      return 'Is Campaign';

    default:
      return value;
  }
};
export default groupByLabel;