import { LocalCache } from "../../jsx/cache/cache";
export default class {
  static remove() {
    var keys = [];

    for (const key in localStorage) {
      keys.push(key);
    }

    keys.forEach(key => {
      LocalCache.delete(key);
    });
  }

}