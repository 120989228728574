import moment from "moment";
export const compRange = (range, comp) => {
  let result = {
    range: null,
    comp: null
  };

  if (comp === 'prev_year') {
    switch (range) {
      case 'today':
        result.range = moment().format('ll');
        result.comp = moment().subtract(1, 'years').format('ll');
        return result;

      case 'yesterday':
        result.range = moment().subtract(1, 'days').format('ll');
        result.comp = moment().subtract(1, 'years').subtract(1, 'days').format('ll');
        return result;

      case 'this_week':
        result.range = `${moment().startOf('week').format('ll')} - ${moment().format('ll')}`;
        result.comp = `${moment().subtract(1, 'year').startOf('week').format('ll')} - ${moment().subtract(1, 'year').format('ll')}`;
        return result;

      case 'last_week':
        result.range = `${moment().subtract(1, 'week').startOf('week').format('ll')} - ${moment().subtract(1, 'week').format('ll')}`;
        result.comp = `${moment().subtract(1, 'year').subtract(1, 'week').startOf('week').format('ll')} - ${moment().subtract(1, 'year').subtract(1, 'week').format('ll')}`;
        return result;

      case 'this_month':
        result.range = `${moment().startOf('month').format('ll')} - ${moment().format('ll')}`;
        result.comp = `${moment().subtract(1, 'year').startOf('month').format('ll')} - ${moment().subtract(1, 'year').format('ll')}`;
        return result;

      case 'last_month':
        result.range = `${moment().subtract(1, 'month').startOf('month').format('ll')} - ${moment().subtract(1, 'month').format('ll')}`;
        result.comp = `${moment().subtract(1, 'year').subtract(1, 'month').startOf('month').format('ll')} - ${moment().subtract(1, 'year').subtract(1, 'month').format('ll')}`;
        return result;

      case 'this_quarter':
        result.range = `${moment().startOf('quarter').format('ll')} - ${moment().format('ll')}`;
        result.comp = `${moment().subtract(1, 'year').startOf('quarter').format('ll')} - ${moment().subtract(1, 'year').format('ll')}`;
        return result;

      case 'last_quarter':
        result.range = `${moment().subtract(1, 'quarter').startOf('quarter').format('ll')} - ${moment().subtract(1, 'quarter').endOf('quarter').format('ll')}`;
        result.comp = `${moment().subtract(1, 'year').subtract(1, 'quarter').startOf('quarter').format('ll')} - ${moment().subtract(1, 'year').subtract(1, 'quarter').endOf('quarter').format('ll')}`;
        return result;

      case 'this_year':
        result.range = `${moment().startOf('year').format('ll')} - ${moment().format('ll')}`;
        result.comp = `${moment().subtract(1, 'year').startOf('year').format('ll')} - ${moment().subtract(1, 'year').format('ll')}`;
        return result;

      case 'last_year':
        result.range = `${moment().subtract(1, 'year').startOf('year').format('ll')} - ${moment().subtract(1, 'year').format('ll')}`;
        result.comp = `${moment().subtract(2, 'year').startOf('year').format('ll')} - ${moment().subtract(2, 'year').format('ll')}`;
        return result;

      case 'all_time':
        return result;

      default:
        return result;
    }
  } else {
    switch (range) {
      case 'today':
        result.range = moment().format('ll');
        result.comp = moment().subtract(1, 'days').format('ll');
        return result;

      case 'yesterday':
        result.range = `${moment().subtract(1, 'days').format('ll')}`;
        result.comp = `${moment().subtract(2, 'days').format('ll')}`;
        return result;

      case 'this_week':
        result.range = `${moment().startOf('week').format('ll')} - ${moment().format('ll')}`;
        result.comp = `${moment().subtract(1, 'week').startOf('week').format('ll')} - ${moment().subtract(1, 'week').format('ll')}`;
        return result;

      case 'last_week':
        result.range = `${moment().subtract(1, 'week').startOf('week').format('ll')} - ${moment().subtract(1, 'week').format('ll')}`;
        result.comp = `${moment().subtract(2, 'week').startOf('week').format('ll')} - ${moment().subtract(2, 'week').format('ll')}`;
        return result;

      case 'this_month':
        result.range = `${moment().startOf('month').format('ll')} - ${moment().format('ll')}`;
        result.comp = `${moment().subtract(1, 'month').startOf('month').format('ll')} - ${moment().subtract(1, 'month').format('ll')}`;
        return result;

      case 'last_month':
        result.range = `${moment().subtract(1, 'month').startOf('month').format('ll')} - ${moment().subtract(1, 'month').format('ll')}`;
        result.comp = `${moment().subtract(2, 'month').startOf('month').format('ll')} - ${moment().subtract(2, 'month').format('ll')}`;
        return result;

      case 'this_quarter':
        result.range = `${moment().startOf('quarter').format('ll')} - ${moment().format('ll')}`;
        result.comp = `${moment().subtract(1, 'quarter').startOf('quarter').format('ll')} - ${moment().subtract(1, 'quarter').format('ll')}`;
        return result;

      case 'last_quarter':
        result.range = `${moment().subtract(1, 'quarter').startOf('quarter').format('ll')} - ${moment().subtract(1, 'quarter').endOf('quarter').format('ll')}`;
        result.comp = `${moment().subtract(2, 'quarter').startOf('quarter').format('ll')} - ${moment().subtract(2, 'quarter').endOf('quarter').format('ll')}`;
        return result;

      case 'this_year':
        result.range = `${moment().startOf('year').format('ll')} - ${moment().format('ll')}`;
        result.comp = `${moment().subtract(1, 'year').startOf('year').format('ll')} - ${moment().subtract(1, 'year').format('ll')}`;
        return result;

      case 'last_year':
        result.range = `${moment().subtract(1, 'year').startOf('year').format('ll')} - ${moment().subtract(1, 'year').format('ll')}`;
        result.comp = `${moment().subtract(2, 'year').startOf('year').format('ll')} - ${moment().subtract(2, 'year').format('ll')}`;
        return result;

      case 'all_time':
        return result;

      default:
        return result;
    }
  }
};
export default compRange;