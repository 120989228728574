import moment from "moment";
export const dynamicFormat = (value, defaultIfNull = '') => {
  if (!value) {
    return defaultIfNull;
  }

  if (value instanceof Date) {
    value = moment(value).format('YYYY-MM-DD');
  } else if (typeof value === 'number') {
    if (Number.isInteger(value)) {
      value = value.toString();
    } else {
      value = value.toFixed(2);
      value = value.toString();
    }
  } else {
    value = value.toString();
  }

  return value;
};
export default dynamicFormat;