function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import moment from "moment";
export default class _class {}

_defineProperty(_class, "process", (activity, selectedDate, sessionId) => {
  var impressions = [];
  var dates = [];
  var imp = activity[0].impression;
  var tConversions = activity[0].conversion;
  var tEngagements = activity[0].engagement;
  var seenDates = {
    first: '----',
    last: '----'
  };
  var result = imp.reduce((r, a) => {
    r[a.sessionId] = r[a.sessionId] || [];
    r[a.sessionId].push(a);
    return r;
  }, Object.create(null));

  for (const [key, value] of Object.entries(result)) {
    var d = {
      id: key,
      text: null,
      value: null
    };
    var imp = value.sort((a, b) => a.date - b.date);
    d.value = new Date(imp[0].date);
    d.text = moment(imp[0].date).format('ll');
    dates.push(d);
  }

  if (dates.length) {
    dates = dates.sort((a, b) => a.value - b.value);
    if (!sessionId) sessionId = dates[0].id;
    if (!selectedDate) selectedDate = dates[0].text;
    impressions = result[sessionId];
    impressions = impressions.sort((a, b) => a.date - b.date);
    seenDates = _class.getSeenDates(result, dates, seenDates);
  }

  var conversions = _class.getRowsFromSessionId(tConversions, sessionId);

  var engagements = _class.getRowsFromSessionId(tEngagements, sessionId);

  return {
    impressions,
    dates,
    selectedDate,
    conversions,
    engagements,
    seenDates
  };
});

_defineProperty(_class, "getRowsFromSessionId", (arr, sessionId) => {
  var rows = [];
  if (!arr) arr = [];
  arr.forEach(row => {
    if (row.sessionId === sessionId) {
      rows.push(row);
    }
  });
  return rows;
});

_defineProperty(_class, "getSeenDates", (result, dates, seenDates) => {
  if (!dates || dates.length < 1) {
    return seenDates;
  }

  var length = dates.length;
  var firstDate = dates[0];
  var lastDate = dates[length - 1];
  var firstSessionId = firstDate.id;
  var lastSessionId = lastDate.id;
  var firstImpressions = result[firstSessionId];
  var lastImpressions = result[lastSessionId];
  seenDates.first = moment(firstImpressions[0].date).startOf('day').fromNow();
  var lastImpressionsLength = lastImpressions.length;
  seenDates.last = moment(lastImpressions[lastImpressionsLength - 1].date).startOf('day').fromNow();
  return seenDates;
});