import Service from '../../plunk/service/service';
import endpoints from '../endpoints';
import account from '../data/account';
export default class ServiceFactory {
  static getType(api, type = 'activity') {
    class FService extends Service {}

    ;
    FService.rootEndpoint = endpoints[type].root + api;
    var accountData = account.get();
    var tenantId = accountData.tenantId;
    FService.account = tenantId;
    FService.headers = ['x-app-id:' + tenantId];

    FService.onAuthTokenFailure = (err, callback) => {
      callback(err, null);
      FService.removeTokens();
      location.href = '/Auth/SignIn';
    };

    return FService;
  }

}