export const compLabel = value => {
  switch (value) {
    case 'prev_year':
      return 'Previous Year';

    case 'prev_period':
      return 'Previous Period';

    case 'unavailable':
      return 'Unavailable';

    default:
      return 'Unavailable';
  }
};
export default compLabel;