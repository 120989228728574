export default class {
  static get() {
    var qs = '';
    var search = location.search;
    search = search.replace('?', '');
    let query = new URLSearchParams(search);
    let $where = query.get('$where');
    if ($where) $where = $where.replace(/&&/g, '%26%26');
    let $params = query.get('$params');

    if ($where && $params) {
      qs = '?$where=' + $where + '&$params=' + $params;
    }

    return qs;
  }

  static getValue(url, key) {
    var split = url.split('?');
    var queryString = split[1];

    if (queryString) {
      let query = new URLSearchParams(queryString);
      return query.get(key);
    } else {
      return null;
    }
  }

  static get AND() {
    return '%26%26';
  }

  static get OR() {
    return '%7C%7C';
  }

  static get PIPE() {
    return '%7C';
  }

  static WHERE($where) {
    if (!$where) return '';
    var AND = this.AND;
    var PIPE = this.PIPE;
    $where = $where.replace(/&&/g, AND);
    $where = $where.replace(/\|/g, PIPE);
    return $where;
  }

  static PARAMS($params) {
    if (!$params) return '';
    var PIPE = this.PIPE;
    $params = $params.replace(/\|/g, PIPE);
    return $params;
  }

}