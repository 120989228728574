export default class {
  static isJson(str) {
    if (!str) return false;

    try {
      var p = JSON.parse(str);

      if (typeof p === 'number') {
        return false;
      } else if (p instanceof Date) {
        return false;
      } else if (typeof p === 'boolean') {
        return false;
      } else {
        return true;
      }
    } catch (ex) {
      return false;
    }
  }

}