import json from "./json";
import camelCaseToTitle from "./camel-to-title";
import capitalize from "./capitalize";
export default class {
  static get displayLimit() {
    return 9;
  }

  static parse(data) {
    var rows = [];
    var columns = [];
    data.forEach(item => {
      var value = item.keyValue;

      if (json.isJson(value)) {
        var obj = JSON.parse(value);

        for (const [key, value] of Object.entries(obj)) {
          if (Array.isArray(value)) {
            obj[key] = JSON.stringify(value);
          }
        }

        rows.push(obj);
      }
    });
    var firstRow = {};

    if (rows.length) {
      firstRow = rows[0];
    } else {
      console.log('no rows');
      return this.parseNonJsonValuePayload(data);
    }

    var index = 0;
    var displayLimit = this.displayLimit;
    var columnDisplay = {};

    for (const [key, value] of Object.entries(firstRow)) {
      var columnObject = {
        prop: key,
        title: camelCaseToTitle(key),
        field: capitalize(key),
        sortable: false
      };
      columns.push(columnObject);

      if (index < displayLimit) {
        columnDisplay[key] = true;
      } else {
        columnDisplay[key] = false;
      }

      index += 1;
    }

    return {
      rows,
      columns,
      columnDisplay
    };
  }

  static resetProps(keyNameProps) {
    for (const [key, value] of Object.entries(keyNameProps)) {
      keyNameProps[key] = 'OFF';
    }

    return keyNameProps;
  }

  static getRowObject(keyNameProps) {
    var row = {};

    for (const [key, value] of Object.entries(keyNameProps)) {
      row[key] = '';
    }

    return row;
  }

  static isRowObjectEmpty(row) {
    var empty = true;

    for (const [key, value] of Object.entries(row)) {
      if (value) {
        empty = false;
      }
    }

    return empty;
  }

  static parseNonJsonValuePayload(data) {
    var rows = [];
    var columns = [];
    var keyNameProps = {};
    data.forEach(item => {
      var prop = item.keyName;
      keyNameProps[prop] = 'OFF';
    });
    var propsCount = 0;

    for (const [key, value] of Object.entries(keyNameProps)) {
      propsCount += 1;
    }

    var row = this.getRowObject(keyNameProps);
    data.forEach(item => {
      var prop = item.keyName;
      var value = item.keyValue;

      if (keyNameProps[prop] === 'OFF') {
        keyNameProps[prop] = 'ON';
        row[prop] = value;
      } else {
        rows.push(row);
        keyNameProps = this.resetProps(keyNameProps);
        row = this.getRowObject(keyNameProps);
        keyNameProps[prop] = 'ON';
        row[prop] = value;
      }
    });

    if (!this.isRowObjectEmpty(row)) {
      rows.push(row);
    }

    var index = 0;
    var displayLimit = this.displayLimit;
    var columnDisplay = {};

    for (const [key, value] of Object.entries(keyNameProps)) {
      var columnObject = {
        prop: key,
        title: camelCaseToTitle(key),
        field: capitalize(key),
        sortable: false
      };
      columns.push(columnObject);

      if (index < displayLimit) {
        columnDisplay[key] = true;
      } else {
        columnDisplay[key] = false;
      }

      index += 1;
    }

    return {
      rows,
      columns,
      columnDisplay
    };
  }

}