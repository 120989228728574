export const frequency = [{
  label: 'Daily',
  value: 'Daily'
}, {
  label: 'Weekly',
  value: 'Weekly'
}, {
  label: 'Monthly',
  value: 'Monthly'
}];
export const times = [{
  label: '12:00AM',
  value: 0
}, {
  label: '1:00AM',
  value: 1
}, {
  label: '2:00AM',
  value: 2
}, {
  label: '3:00AM',
  value: 3
}, {
  label: '4:00AM',
  value: 4
}, {
  label: '5:00AM',
  value: 5
}, {
  label: '6:00AM',
  value: 6
}, {
  label: '7:00AM',
  value: 7
}, {
  label: '8:00AM',
  value: 8
}, {
  label: '9:00AM',
  value: 9
}, {
  label: '10:00AM',
  value: 10
}, {
  label: '11:00AM',
  value: 11
}, {
  label: '12:00PM',
  value: 12
}, {
  label: '1:00PM',
  value: 13
}, {
  label: '2:00PM',
  value: 14
}, {
  label: '3:00PM',
  value: 15
}, {
  label: '4:00PM',
  value: 16
}, {
  label: '5:00PM',
  value: 17
}, {
  label: '6:00PM',
  value: 18
}, {
  label: '7:00PM',
  value: 19
}, {
  label: '8:00PM',
  value: 20
}, {
  label: '9:00PM',
  value: 21
}, {
  label: '10:00PM',
  value: 22
}, {
  label: '11:00PM',
  value: 23
}];
export const timeZones = [{
  label: '(GMT -8:00) Pacific Time (US & Canada)',
  value: '-08:00'
}, {
  label: '(GMT -7:00) Mountain Time (US & Canada)',
  value: '-07:00'
}, {
  label: '(GMT -6:00) Central Time (US & Canada), Mexico City',
  value: '-06:00'
}, {
  label: '(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima',
  value: '-05:00'
}];
export const timeZone = val => {
  val = val.toLowerCase();

  if (val === 'pst') {
    return {
      label: '(GMT -8:00) Pacific Time (US & Canada)',
      value: '-08:00'
    };
  } else if (val === 'mst') {
    return {
      label: '(GMT -7:00) Mountain Time (US & Canada)',
      value: '-07:00'
    };
  } else if (val === 'cst') {
    return {
      label: '(GMT -6:00) Central Time (US & Canada), Mexico City',
      value: '-06:00'
    };
  } else {
    return {
      label: '(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima',
      value: '-05:00'
    };
  }
};