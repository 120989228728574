import { LocalCache } from "../../jsx/cache/cache";
export default class {
  static get() {
    var key = 'plunk-tenant';
    var value = LocalCache.get(key);

    if (!value) {
      value = {
        tenantId: 'anbsezhvanaj',
        account: 'RobbStucky',
        company: 'Robb & Stucky',
        domain: 'https://robbstucky.com',
        whiteLabel: true,
        showCampaigns: false,
        product: {
          url: '/Product/Detail/:sku/:vendor/:name',
          hasName: true,
          hasSku: true
        }
      };
    }

    return value;
  }

  static set(value) {
    var key = 'plunk-tenant';
    LocalCache.set(key, value);
  }

  static getEmail() {
    var key = 'authTokens';
    var value = LocalCache.get(key);
    if (value) return value.email;else return null;
  }

}