import { AuthBase } from './auth-base';
import { Utility } from '../utility/utility';
import native from '../utility/utils/native';
import object from '../utility/utils/object';
import PaginationProvider from './pagination';
import DynamicLinq from './linq';

var JSONbig = require('json-bigint');

export default class Service extends AuthBase {
  constructor(endpoint, ...params) {
    super();
    this.endpoint = endpoint;
    this.constructor.endpoint = endpoint;
    this.cors = true;
    this.$query = {};

    if (params.length > 0) {
      let p1 = params[0];
      if (Array.isArray(p1)) this._headers = p1;
    }
  }

  static extend() {
    return native.extends(this);
  }

  get(params, callback) {
    var value;
    if (!params) value = null;else if (params && typeof params === 'object') value = this.getParamsString(params);else if (typeof params === 'string') {
      value = '/' + params;
    } else {
      value = null;
    }
    return this.fetchEndpoint('get', value, callback);
  }

  post(value, callback) {
    return this.fetchEndpoint('post', value, callback);
  }

  put(value, callback) {
    return this.fetchEndpoint('put', value, callback);
  }

  delete(id, callback) {
    return this.fetchEndpoint('delete', id, callback);
  }

  paginate(params) {
    this.$query = {};

    try {
      params.page = parseInt(params.page);
    } catch (ex) {
      params.page = 1;
    }

    this.$query.paginate = params;
    return this;
  }

  orderBy(val) {
    if (val && !object.isEmpty(val)) this.$query.orderBy = val;
    return this;
  }

  orderByDesc(val) {
    if (val && !object.isEmpty(val)) this.$query.orderByDesc = val;
    return this;
  }

  async getAsync(params) {
    var method = 'get';
    var value;
    if (!params) value = null;else if (typeof params === 'object') value = this.getParamsString(params);else value = '/' + params;
    return new Promise((resolve, reject) => {
      this.fetchEndpoint(method, value, (err, data) => {
        if (err) reject(err);else resolve(data);
      });
    });
  }

  async postAsync(value) {
    var method = 'post';
    return new Promise((resolve, reject) => {
      this.fetchEndpoint(method, value, (err, data) => {
        if (err) reject(err);else resolve(data);
      });
    });
  }

  async putAsync(value) {
    var method = 'put';
    return new Promise((resolve, reject) => {
      this.fetchEndpoint(method, value, (err, data) => {
        if (err) reject(err);else resolve(data);
      });
    });
  }

  async deleteAsync(id) {
    var method = 'delete';
    return new Promise((resolve, reject) => {
      this.fetchEndpoint(method, id, (err, data) => {
        if (err) reject(err);else resolve(data);
      });
    });
  }

  clearRootEndpoint() {
    this.rootEndpoint = null;
  }

  fetchEndpoint(methodParam, value, callback) {
    let retry = false;
    let isQuery = false;
    let method = methodParam;

    if (typeof methodParam === 'object') {
      retry = true;
      method = methodParam.method;
    }

    let refreshToken = this.refreshToken;
    let Ok = false;
    let status = null;
    let statusText = null;
    let errObj = {
      message: null,
      statusCode: null
    };
    let fetchObj = {};

    if (method === 'get' && !object.isEmpty(this.$query.paginate)) {
      isQuery = true;
      fetchObj = this.queryFetchObj();
    } else {
      fetchObj = this.getFetchObj(method, value, this.endpoint);
    }

    fetch(fetchObj.url, fetchObj.options).then(response => {
      Ok = response.ok;
      status = response.status;
      statusText = response.statusText;
      return response;
    }).then(response => response.text()).then(text => {
      if (Ok) {
        try {
          let data = JSONbig.parse(text); //let data = JSON.parse(text);

          /*let data = JSON.parse(text, (key, value) => {
              if (typeof value === 'number' && !Number.isSafeInteger(value)) {
                  //let strBig = text.match(new RegExp(`(?:"${key}":)(.*?)(?:,)`))[1] // get the original value using regex expression 
                  console.log(value);
                  let strBig=value.toString();
                  return strBig;
              }
              return value
          })*/

          if (isQuery) {
            let result = PaginationProvider.get(this.$query, data);
            this.$query = {};
            callback(null, result);
          } else {
            callback(null, data);
          }
        } catch (ex) {
          this.$query = {};
          callback(null, text);
        }
      } else if (status == 401 && refreshToken && !retry) {
        this.requestNewTokens(refreshToken, method, value, callback);
      } else {
        errObj.statusCode = status;
        this.$query = {};

        try {
          let data = JSONbig.parse(text); //let data = JSON.parse(text);

          /*let data = JSON.parse(text, (key, value) => {
              if (typeof value === 'number' && !Number.isSafeInteger(value)) {
                  //let strBig = text.match(new RegExp(`(?:"${key}":)(.*?)(?:,)`))[1] // get the original value using regex expression 
                  console.log(value);
                  let strBig = value.toString();
                  return strBig //should be BigInt(strBig) - BigInt function is not working in this snippet
              }
              return value
          })*/

          if (data && data.message) errObj.message = data.message;else errObj.message = statusText;
          callback(errObj, null);
        } catch (ex) {
          if (text && text !== '') errObj.message = text;else errObj.message = statusText;
          callback(errObj, null);
        }
      }
    }).catch(err => {
      if (typeof err === 'string') statusText = err;
      errObj.statusCode = 500;
      errObj.message = statusText;
      callback(errObj, null);
    });
  }

  requestNewTokens(refreshToken, method, value, callback) {
    let methodParam = {
      method: method,
      retry: true
    };
    this.getFreshTokens(refreshToken, (err, data) => {
      if (!err) this.fetchEndpoint(methodParam, value, callback);else callback(err, null);
    });
  }

  queryFetchObj() {
    let query = this.$query;
    let url = '';

    if (this.constructor.rootEndpoint) {
      url = this.constructor.rootEndpoint;
    } else {
      url = this.getEndpoint(this.endpoint);
    }

    if (query && typeof query.paginate !== 'undefined') {
      url += DynamicLinq.paginate(url, query.paginate);
    }

    if (query && typeof query.orderBy !== 'undefined' && !object.isEmpty(query.orderBy)) {
      url += DynamicLinq.orderBy(url, query.orderBy);
    }

    if (query && typeof query.orderByDesc !== 'undefined' && !object.isEmpty(query.orderByDesc)) {
      url += DynamicLinq.orderByDesc(url, query.orderBy, query.orderByDesc);
    }

    let obj = {
      url: url,
      options: {
        method: 'get'
      }
    };
    obj.options.mode = 'cors';
    obj.options.headers = this.getHeaders();
    return obj;
  }

}